import { Spin, Table, TableColumnsType } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left Square.svg';
import rightArrowIcon from 'assets/Arrow - Right Square.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import searchIcon from 'assets/search.svg';
import AgentSideBar from 'components/agentComponents/AgentSideBar';
import TransactionModal from 'components/agentComponents/AgentTransactionModal';
import { useEffect, useState } from 'react';
import { useGetAgentTransactionMutation } from 'services/agentServices/agent';
import { useAppDispatch } from 'store';
import { setTransaction } from 'store/Transaction';
import { DataTypes, Transactions } from 'types/agent';

const Transaction = () => {
  const [getAgentTransaction] = useGetAgentTransactionMutation();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<DataTypes | null>(null);
  const [data, setData] = useState<DataTypes[]>([]);
  const [loading, setLoading] = useState(true);
  const [isRotated, setIsRotated] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const handleRefresh = () => {
    setIsRotated(!isRotated);
    setLoading(true);
    getAgentTransaction({})
      .unwrap()
      .then((response) => {
        const transactions = response.data.map((transaction: Transactions) => {
          const formatAmount = (value: string) => {
            return new Intl.NumberFormat('en-US').format(parseFloat(value));
          };

          return {
            key: transaction.id,
            date: new Date(transaction.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }),
            rate: transaction.payment_info.transaction.rate,
            amount: `${transaction.amount.currency} ${formatAmount(transaction.amount.value)}`,
            payment_type: transaction.payment_info.transaction.type,
            purpose: transaction.payment_info.transaction.purpose,
            status: transaction.status,
            fees: `${formatAmount(transaction.fees.value)}`,
            source_amount: transaction.fees.value,
            total_amount: `${formatAmount(transaction.amount.value)}`,
            transaction_type: transaction.status,
            description: transaction.payment_info.transaction.description,
            type: transaction.type,
            beneficiary: {
              bankName: transaction.payment_info.beneficiary.bank_name,
              currency: transaction.payment_info.beneficiary.currency,
              provider: transaction.payment_info.beneficiary.provider,
              bank_code: transaction.payment_info.beneficiary.bank_code,
              bank_name: transaction.payment_info.beneficiary.bank_name,
            },
          };
        });

        dispatch(setTransaction(transactions));
        setData(transactions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '75%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '75%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '65%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '68%',
        };
      default:
        return {};
    }
  };

  const columns: TableColumnsType<DataTypes> = [
    {
      title: 'Date',
      dataIndex: 'date',
      responsive: ['lg'],
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Payment Type',
      dataIndex: 'payment_type',
      responsive: ['md'],
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
    },
    {
      title: 'Status',
      dataIndex: 'transaction_type',
      responsive: ['md'],
      render: (text: string) => {
        const { backgroundColor, color, dotColor, width } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '0px 0  0 10px',
              borderRadius: '16px',
              display: 'flex',
              gap: '5px',
              paddingLeft: '15px',
              alignItems: 'center',
              width: width,
              height: '22px',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
  ];

  const showModal = (transaction: DataTypes) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    handleRefresh();
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
  };

  const filteredData = data.filter((item) => {
    const matchesSearchText =
      item.type.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase());

    const matchesFilter =
      activeFilter === 'All' ||
      (activeFilter === 'Initiated' && item.status === 'Initiated') ||
      (activeFilter === 'Processing' && item.status === 'Processing') ||
      (activeFilter === 'Completed' && item.status === 'Completed') ||
      (activeFilter === 'Failed' && item.status === 'Failed');

    return matchesSearchText && matchesFilter;
  });

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filters = [
    { label: 'All', width: '44px' },
    { label: 'Initiated', width: '79px' },
    { label: 'Processing', width: '75px' },
    { label: 'Failed', width: '50px' },
    { label: 'Completed', width: '80px' },
  ];

  return (
    <AgentSideBar>
      <div className="h-[100vh]">
        <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px] w-[100%]">
          <div className="flex justify-between w-[100%] border-b border-b-[#EAECF0] p-[15px]">
            <div className="font-[900] text-[#343434] text-[14px] md:text-[24px] leading-[40px] w-[20%]">
              <h1>Transactions</h1>
            </div>
            <div className="flex justify-between lg:w-[80%]">
              <div className="hidden md:flex items-center gap-2 text-[#344054] font-[700] text-[12.57px] leading-[18.86px]">
                {filters.map(({ label, width }) => (
                  <button
                    key={label}
                    className={`border border-[#8894EC] py-[7px] px-[20px] h-[33px] flex justify-center items-center rounded-[30px] ${
                      activeFilter === label ? 'bg-[#8894EC33] text-[#8894EC]' : 'text-[#8894EC]'
                    }`}
                    style={{ width }}
                    onClick={() => handleFilterChange(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              <div className="input flex items-center md:hidden lg:flex">
                <img
                  src={searchIcon}
                  alt={searchIcon}
                  className="relative left-[30px] -ml-[15px]"
                />
                <input
                  type="text"
                  placeholder="Search transactions"
                  value={searchText}
                  onChange={handleSearch}
                  className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] py-[16.21px] w-[100px] lg:w-[250px]"
                />
              </div>
              <button
                onClick={handleRefresh}
                className="flex items-center font-[500] text-[10px] md:text-[14px] leading-[20px]"
              >
                <img
                  src={refreshIcon}
                  alt={refreshIcon}
                  className={`transition-transform duration-500 ease-in-out ${
                    isRotated ? 'rotate-360' : ''
                  }`}
                />
                <p>Refresh</p>
              </button>
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="flex flex-col">
              <Table
                columns={columns}
                dataSource={currentRecords}
                className="table cursor-pointer"
                id="table"
                onRow={(record) => {
                  return {
                    onClick: () => {
                      showModal(record);
                    },
                  };
                }}
              />
              {filteredData.length > recordsPerPage && (
                <div className="text-[#1174ED] font-[700] text-[14px] leading-[18.9px] flex justify-end py-[10px] md:pr-[50px] items-center gap-2">
                  <button className="mx-1 cursor-pointer" onClick={prevPage}>
                    <img src={leftArrowIcon} alt={leftArrowIcon} />
                  </button>
                  <button className="mx-1 cursor-pointer" onClick={nextPage}>
                    <img src={rightArrowIcon} alt={rightArrowIcon} />
                  </button>
                </div>
              )}
            </div>
          )}
          <TransactionModal
            openModal={isModalOpen}
            close={handleCancel}
            transaction={selectedTransaction}
          />
        </div>
      </div>
    </AgentSideBar>
  );
};

export default Transaction;
