import { Modal, notification } from 'antd';
import cancelIcon from 'assets/cancel.svg';
import infoIcon from 'assets/info.svg';
import tickIcon from 'assets/tick.svg';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AGENT_LOGIN_ROUTE, LOGIN_ROUTE } from 'routes/path';

interface LogOutModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  userType: 'user' | 'agent';
}

const LogOutModal: FC<LogOutModalProps> = ({ isOpen, handleCancel, userType }) => {
  const navigate = useNavigate();

  const confirmLogout = () => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=${new Date(0).toUTCString()};path=/`;
    }
    const loginRoute = userType === 'agent' ? AGENT_LOGIN_ROUTE : LOGIN_ROUTE;

    navigate(loginRoute);
    notification.success({
      message: 'Success',
      description: 'Logout Successful',
    });
  };

  return (
    <Modal open={isOpen} onCancel={handleCancel} centered className="logout">
      <div className="flex justify-center items-center gap-[10px] h-[200px] lg:h-[230px]">
        <div className="flex flex-col gap-3 md:gap-6 items-center w-[100%] pt-10">
          <div className="flex flex-col items-center gap-4 font-medium leading-[30px] text-[20px] px-[18px] text-center">
            <div>
              <img src={infoIcon} alt={infoIcon} />
            </div>
            <p>
              You are about to <span className="font-[700]">Logout</span> of your account
            </p>
          </div>
          <div className="border border-[#F2F5F8] w-[100%]"></div>
          <div className="flex gap-4 font-medium text-[18px] leading-[20px] px-[18px]">
            <button
              onClick={confirmLogout}
              className="flex justify-center items-center gap-2 px-[10px] md:px-[24px] py-[13px] h-[48px] rounded-md bg-[#FB382B] text-[#FFFFFF]"
            >
              Yes, go ahead
              <img src={tickIcon} alt={tickIcon} />
            </button>
            <button
              onClick={handleCancel}
              className="flex justify-center items-center gap-2 px-[10px] md:px-[24px] py-[13px] h-[48px] rounded-md border border-[#1174ED] text-[#1174ED]"
            >
              No, cancel
              <img src={cancelIcon} alt={cancelIcon} />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogOutModal;
