import { Button, Checkbox, Input } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left b.svg';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import KycSideBar from 'components/KybSideBar';
import { ErrorMessage, Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ADDRESS_ROUTE, BASE_ROUTE } from 'routes/path';
import { useGetBusinessMutation } from 'services/business';
import { useGetKYBMutation } from 'services/kyb';
import { useAppDispatch } from 'store';
import { businessSelector, setBusiness } from 'store/business';
import { setKYB } from 'store/kyb';
import * as Yup from 'yup';

const Details = () => {
  const { business } = useSelector(businessSelector);
  const [getBusiness] = useGetBusinessMutation();
  const [getKYB] = useGetKYBMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    business.name, business.business_identification_number;
  }, [business.name, business.business_identification_number]);

  useEffect(() => {
    getBusiness({})
      .unwrap()
      .then((response) => {
        dispatch(setBusiness(response.data));
        getUserKyb();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBusiness, getKYB]);

  const getUserKyb = () => {
    getKYB({})
      .unwrap()
      .then((response) => {
        dispatch(setKYB(response.data));
      });
  };

  const validationSchema = Yup.object().shape({
    legalName: Yup.string().required('Legal Business Name is required'),
    businessId: Yup.string()
      .matches(/^\d*$/, 'Business ID must be a number')
      .required('Business registration number is required'),
    isEditable: Yup.boolean(),
  });

  const handleSubmit = (values: { legalName: string; businessId: string; isEditable: boolean }) => {
    navigate(ADDRESS_ROUTE, { state: values });
  };

  return (
    <KycSideBar>
      <div className="bg-[#fff] rounded-[10px] sha py-[60px] px-[40px] lg:w-[1030px]">
        <Formik
          initialValues={{
            legalName: business.name,
            businessId: business.business_identification_number,
            isEditable: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <FormikForm className="flex flex-col justify-between h-[550px]">
              <div className="flex flex-col gap-5 lg:w-[703px]">
                <div className="flex flex-col">
                  <h1 className="text-[#343434] font-[900] leading-[30.61px] text-[28px]">
                    Basic Business Details
                  </h1>
                  <p className="font-[400] text-[16px] leading-[21.6px] mt-3 w-[450px] text-[#000000]">
                    Provide the following information about your business.
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-[500] text-[16px] leading-[22.4px] text-[#000000]">
                    Legal Business Name*
                  </p>
                  <Field name="legalName">
                    {({ field }: FieldProps<string>) => (
                      <Input
                        {...field}
                        placeholder="Registered Business Name"
                        readOnly={!values.isEditable}
                        onChange={(e) => setFieldValue('legalName', e.target.value)}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="legalName" component="div" className="text-[red]" />
                </div>
                <div className="text-[#000000] font-[500] text-[14px] leading-[20px] flex items-center gap-2 boxcheck">
                  <Checkbox onChange={(e) => setFieldValue('isEditable', e.target.checked)} />
                  <p>Select if your business has another trading name</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-[500] text-[16px] leading-[22.4px] text-[#000000]">
                    Business registration number (RC)*
                  </p>
                  <Field name="businessId">
                    {({ field }: FieldProps<string>) => (
                      <Input
                        {...field}
                        placeholder="000000"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setFieldValue('businessId', value);
                          }
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="businessId" component="div" className="text-[red]" />
                </div>
              </div>
              <div className="flex justify-between items-center lg:w-[703px] mt-[50px]">
                <Link to={BASE_ROUTE}>
                  <button className="flex items-center gap-1 text-[#1174ED] font-[700] leading-[22.4px] text-[16px]">
                    <img src={leftArrowIcon} alt={leftArrowIcon} />
                    <p>Save and continue later</p>
                  </button>
                </Link>
                <div className="flex items-center gap-2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px]"
                  >
                    Next
                    <img src={rightArrowIcon} alt={rightArrowIcon} />
                  </Button>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </KycSideBar>
  );
};

export default Details;
