import { DatePicker, Form, Input, message } from 'antd';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import KycSideBar from 'components/KybSideBar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from 'routes/path';
import { useShareHolderVerifyMutation } from 'services/kyb';
import { authSelector } from 'store/auth';
import { KybShareHolderVerifyInput } from 'types/kyb';
import * as Yup from 'yup';
import 'youverify-web-sdk';

interface LivenessCheckResponse {
  photo: string;
  passed: boolean;
}

const validationSchema = Yup.object().shape({
  bvn: Yup.string()
    .required('BVN is required')
    .matches(/^\d{11}$/, 'BVN must be 11 digits'),
  dateOfBirth: Yup.date().required('Date of Birth is required'),
});

const Verification = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [bvn, setBvn] = useState<string>('');
  const { user } = useSelector(authSelector);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [verifyKyb] = useShareHolderVerifyMutation();

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
  }, [user.first_name, user.last_name]);

  const onFinish = async (image: string) => {
    try {
      await validationSchema.validate({ bvn, dateOfBirth });
      const payload: KybShareHolderVerifyInput = {
        id: bvn,
        is_subject_consent: true,
        metadata: {
          userId: user.id,
          env: import.meta.env.VITE_ENV || 'dev',
          type: 'bvn',
        },
        validations: {
          data: {
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth,
          },
          selfie: {
            image: image,
          },
        },
        token: '',
      };

      verifyKyb(payload)
        .unwrap()
        .then(() => {
          message.success('KYC verification successful:');
          navigate(BASE_ROUTE);
        })
        .catch((error) => {
          message.error('KYC verification failed:', error);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        message.error(err.message);
      }
    }
  };

  const onLivenessCheck = async () => {
    try {
      await validationSchema.validate({ bvn, dateOfBirth });
      const livenessCheckModule = new YouverifySDK.liveness({
        publicMerchantKey: '63077a358629e9682015b779',
        dev: false,
        personalInformation: {
          firstName: firstName,
        },
        appearance: {
          greeting: 'We will need to perform a liveness test. It will only take a moment.',
          actionText: 'Perform liveness test',
          buttonBackgroundColor: '#1174ED',
          buttonTextColor: '#ffffff',
          primaryColor: '#46B2C8',
        },
        onSuccess: (res: LivenessCheckResponse) => {
          onFinish(res.photo);
        },
        onFailure: (err: Error) => {
          message.error(err.message);
        },
        onClose: () => {
          // console.log('closed');
        },
      });
      livenessCheckModule.initialize();
      livenessCheckModule.start();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        message.error(err.message);
      }
    }
  };

  return (
    <KycSideBar>
      <div className="bg-[#fff] rounded-[10px] sha py-[60px] px-[40px] lg:w-[1030px] flex flex-col justify-between">
        <Form form={form} onFinish={onLivenessCheck}>
          <div className="flex flex-col gap-5 lg:w-[641px]">
            <h1 className="font-[900] text-[28px] leading-[30.61px] text-[#343434]">
              Activate your account
            </h1>
            <p className="font-[400] text-[16px] leading-[21.6px] mt-3 w-[450px] text-[#000000]">
              We are required to collect and verify your personal information. Provide the following
              information for your KYC verification.
            </p>
            <div className="flex flex-col gap-4">
              <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2 relative top-4 -mt-4">
                <p className="text-[#000000]">
                  Your BVN (Bank Verification Number)<span className="text-[red]">*</span>
                </p>
                <Form.Item>
                  <Input
                    placeholder="Enter BVN"
                    value={bvn}
                    onChange={(e) => setBvn(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2">
                <p className="text-[#000000]">
                  Legal First Name (as it appears on your documents)
                  <span className="text-[red]">*</span>
                </p>
                <Input
                  placeholder="Enter your first name"
                  onFocus={(e) => (e.target.readOnly = !!firstName)}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="flex gap-2 justify-between">
                <div className="font-[500] text-[16px] leading-[22.4px] w-[50%] flex flex-col gap-2">
                  <p className="text-[#000000]">
                    Legal Last Name<span className="text-[red]">*</span>
                  </p>
                  <Input
                    placeholder="Enter your legal last name"
                    value={lastName}
                    onFocus={(e) => (e.target.readOnly = !!firstName)}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className="font-[500] text-[16px] leading-[22.4px] w-[50%] flex flex-col gap-2">
                  <p className="text-[#000000]">
                    Middle Name<span className="text-[red]">*</span>
                  </p>
                  <Input placeholder="Enter your middle name" />
                </div>
              </div>
              <Form.Item>
                <p className="font-[500] text-[16px] leading-[22.4px] text-[#000000]">
                  Date of Birth<span className="text-[red]">*</span>
                </p>
                <DatePicker
                  className="lg:w-[641px] md:w-[28.5rem] font-[500] text-[16px] leading-[22.4px]"
                  placeholder="Select date of birth"
                  value={dateOfBirth ? moment(dateOfBirth, 'YYYY-MM-DD') : undefined}
                  onChange={(date, dateString) => {
                    if (typeof dateString === 'string') {
                      setDateOfBirth(dateString);
                    } else if (Array.isArray(dateString) && dateString.length > 0) {
                      setDateOfBirth(dateString[0]);
                    }
                  }}
                />
              </Form.Item>
            </div>
            <div className="flex justify-en items-center mt-[50px]">
              <button
                type="submit"
                className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px] w-[100%]"
              >
                Submit
                <img src={rightArrowIcon} alt={rightArrowIcon} />
              </button>
            </div>
          </div>
        </Form>
      </div>
    </KycSideBar>
  );
};

export default Verification;
