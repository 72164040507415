import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Dropdown, Menu, notification, Space, Spin, Table, TableColumnsType } from 'antd';
import arrowIcon from 'assets/Arrow - Down 2.svg';
import tableArrowIcon from 'assets/Arrow - Right 2.svg';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import debitIcon from 'assets/Group 7.svg';
import receiveIcon from 'assets/Group 107.svg';
import usdIcon from 'assets/Group 1171275979.svg';
import sgdIcon from 'assets/Group 1171275982.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import cnyIcon from 'assets/Mask group.svg';
import searchInputIcon from 'assets/search.svg';
import plus1Icon from 'assets/Vector (1).svg';
import AgentSideBar from 'components/agentComponents/AgentSideBar';
import { useEffect, useState } from 'react';
import {
  useGetAgentMutation,
  useGetAgentTransactionMutation,
  useGetAgentWalletMutation,
} from 'services/agentServices/agent';
import { useAppDispatch } from 'store';
import { setTransaction } from 'store/Transaction';
import {
  AccountBalances,
  DataType,
  ErrorResponse,
  Transactions,
  WalletResponse,
} from 'types/agent';

const AgentDashboard = () => {
  const [getAgent] = useGetAgentMutation();
  const [agentName, setAgentName] = useState('');
  const [getAgentTransaction] = useGetAgentTransactionMutation();
  const [getWalletById] = useGetAgentWalletMutation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<DataType[]>([]);
  const [accountBalances, setAccountBalances] = useState<AccountBalances>({});
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const response = await getAgent({});
        if (response?.data) {
          setAgentName(response.data.data.name);
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    };
    fetchAgent();
  }, [getAgent]);

  useEffect(() => {
    const fetchWalletBalances = async () => {
      try {
        const walletResponse: WalletResponse = await getWalletById({}).unwrap();
        const balances: AccountBalances = {};

        walletResponse.data.forEach((wallet) => {
          balances[wallet.currency] = parseFloat(wallet.available).toFixed(2);
        });

        setAccountBalances(balances);
      } catch (error) {
        // console.error('Failed to fetch wallet balances:', error);
      }
    };

    fetchWalletBalances();
  }, [getWalletById]);

  const handleRefresh = () => {
    setIsRotated(!isRotated);
    setLoading(true);
    getAgentTransaction({})
      .unwrap()
      .then((response) => {
        const transactions = response.data.map((transaction: Transactions) => {
          const formatAmount = (value: string) => {
            return new Intl.NumberFormat('en-US').format(parseFloat(value));
          };
          return {
            key: transaction.id,
            date: new Date(transaction.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }),
            rate: transaction.payment_info.transaction.rate,
            amount: `${transaction.amount.currency} ${formatAmount(
              transaction.total_amount.value
            )}`,
            payment_type: transaction.payment_info.transaction.type,
            purpose: transaction.payment_info.transaction.purpose,
            fees: transaction.fees.value,
            source_amount: `${formatAmount(transaction.fees.value)}`,
            total_amount: `${formatAmount(transaction.total_amount.value)}`,
            status: transaction.status,
            description: transaction.payment_info.transaction.description,
            type: transaction.type,
          };
        });
        dispatch(setTransaction(transactions));
        setData(transactions);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '75%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '75%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '65%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '68%',
        };
      default:
        return {};
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      responsive: ['lg'],
      render: (text: string, record: DataType) => {
        const icon =
          record.status === 'Initiated' || record.status === 'Failed' ? debitIcon : receiveIcon;
        return (
          <div className="flex items-center gap-2">
            <img src={icon} alt={icon} className="w-[18.9px] h-[18.9px]" />
            {text}
          </div>
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Payment Type',
      dataIndex: 'payment_type',
      responsive: ['md'],
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      responsive: ['md'],
      render: (text: string) => {
        const { backgroundColor, color, dotColor, width } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '0px 0  0 10px',
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              width: width,
              height: '22px',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
                marginRight: '8px',
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.type.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase())
  );

  const [showAll, setShowAll] = useState(false);
  const rowsToShow = showAll ? data.length : 10;

  const toggleView = () => {
    setShowAll(!showAll);
  };

  const accountData = [
    { icon: usdIcon, currency: 'USD' },
    { icon: cnyIcon, currency: 'CNY' },
    { icon: ngnIcon, currency: 'NGN' },
    { icon: sgdIcon, currency: 'SGD' },
    { icon: hkdIcon, currency: 'HKD' },
  ];

  const availableAccounts = accountData.filter((account) => accountBalances[account.currency]);

  const handleMenuClick = ({ key }: { key: string }) => {
    setSelectedCurrency(key);
  };

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible((prevState) => !prevState);
  };

  const items = availableAccounts.map((account) => ({
    key: account.currency,
    label: (
      <div className="flex items-center gap-2">
        <img src={account.icon} alt={account.currency} className="w-[18px] h-[18px]" />
        {account.currency}
      </div>
    ),
  }));

  return (
    <AgentSideBar>
      <div className="flex flex-col gap-4 md:gap-10">
        <h1 className="text-[#343434] font-[700] text-[18px] md:text-[24px] leading-[40px]">
          Welcome {agentName} 👋🏽,
        </h1>

        <div className="flex flex-col md:flex-row md:items-center gap-3">
          <div className="flex flex-col gap-2">
            <p className="text-[#666666] font-[500] text-[10px] md:text-[14px] leading-[18.9px]">
              Account Balance
            </p>
            <div className="flex flex-col md:flex-row md:items-center md:gap-[30px]">
              <div className="flex items-center gap-1 xl:gap-4 bottom-4 lg:bottom-0 relative md:gap-2 lg:gap-0">
                <div className="flex items-center gap-2">
                  <button onClick={toggleBalanceVisibility}>
                    {isBalanceVisible ? (
                      <EyeOutlined className="text-gray-500" />
                    ) : (
                      <EyeInvisibleOutlined className="text-gray-500" />
                    )}
                  </button>
                  <p className="font-[900] leading-[40px] text-[18px] xl:text-[28px]">
                    {isBalanceVisible
                      ? `${accountBalances[selectedCurrency]} ${selectedCurrency}`
                      : '*******'}
                  </p>
                </div>
                <Dropdown
                  overlay={<Menu onClick={handleMenuClick} items={items} />}
                  trigger={['hover']}
                >
                  <button onClick={(e) => e.preventDefault()} className="relative bottom-2">
                    <Space>
                      <img
                        src={arrowIcon}
                        alt={arrowIcon}
                        className="h-[10px] w-[10px] md:h-[100%] md:w-[100%]"
                      />
                    </Space>
                  </button>
                </Dropdown>
              </div>
              <div className="flex gap-[8px] xl:gap-[16px] font-[700] text-[14px] leading-[18.86px] text-[#1174ED]">
                <button className="flex items-center gap-2 border border-[#1174ED] py-[7px] px-[20px] rounded-[10px]">
                  <img src={plus1Icon} alt={plus1Icon} />
                  Transfer
                </button>
                <button className="flex items-center gap-2 border border-[#1174ED] py-[7px] px-[20px] rounded-[10px]">
                  <img src={plus1Icon} alt={plus1Icon} />
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex justify-start">
            <h1 className="font-[900] text-[18px] md:text-[24px] leading-[40px] text-[#343434]">
              Accounts
            </h1>
          </div>

          <div
            className="flex gap-4 overflow-x-scroll scrollbar-hide"
            style={{
              WebkitOverflowScrolling: 'touch',
            }}
          >
            {availableAccounts.map((account, index) => (
              <div
                key={index}
                className="h-[100px] md:h-[150px] rounded-[10px] bg-[#F5F5F5] md:w-[264px] p-[10px] md:p-[15px] flex flex-col justify-between"
              >
                <div className="font-[900] text-[10px] md:text-[16px] leading-[18px] flex items-center gap-2">
                  <img
                    src={account.icon}
                    alt={account.currency}
                    className="md:w-[42px] md:h-[42px] h-[30px] w-[30px]"
                  />
                  <p>{account.currency}</p>
                </div>
                <div className="flex justify-between gap-1">
                  <div>
                    <p className="text-[#666666] font-[400] text-[8px] md:text-[10px] md:leading-[13.5px]">
                      Local account
                    </p>
                    <p className="text-[#343434] font-[700] text-[10px] md:text-[14px] leading-[18px]">
                      {accountBalances[account.currency] || `${account.currency} not found`}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#666666] font-[400] text-[8px] md:text-[10px] md:leading-[13.5px]">
                      Global account
                    </p>
                    <p className="text-[#343434] font-[700] text-[10px] md:text-[14px] leading-[18px]">
                      {accountBalances[account.currency] || `${account.currency} not found`}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
          <div className="flex flex-col gap-2 md:flex-row justify-between border-b border-b-[#EAECF0] p-[15px]">
            <h1 className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px]">
              Recent Transactions
            </h1>
            <div className="flex gap-3">
              <div className="input flex items-center">
                <img
                  src={searchInputIcon}
                  alt={searchInputIcon}
                  className="relative left-[30px] -ml-[20px] md:-ml-[30px]"
                />
                <input
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="Search for account"
                  className="bg-[#F5F5F5] pl-[35px] lg:pl-[35px] rounded-[10px] h-[40px] py-[16.21px] w-[150px] md:w-[100%] lg:w-[300px]"
                />
              </div>
              <button
                onClick={handleRefresh}
                className="flex items-center font-[500] text-[10px] md:text-[14px] md:leading-[20px]"
              >
                <img
                  src={refreshIcon}
                  alt={refreshIcon}
                  className={`transition-transform duration-500 ease-in-out ${
                    isRotated ? 'rotate-360' : ''
                  }`}
                />
                <p>Refresh</p>
              </button>
              <button
                onClick={toggleView}
                className="flex items-center gap-2 text-[#1174ED] font-[700] text-[10px] md:text-[14px] md:leading-[18.9px] whitespace-nowrap"
              >
                {showAll ? 'View Less' : 'View All'}
                <img src={tableArrowIcon} alt={tableArrowIcon} className="hidden md:block" />
              </button>
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={filteredData.slice(0, rowsToShow)}
              size="middle"
              className="table"
              id="table"
              pagination={false}
            />
          )}
        </div>
      </div>
    </AgentSideBar>
  );
};

export default AgentDashboard;
