import { Modal } from 'antd';
import leftIcon from 'assets/Arrow - Left b.svg';
import logo from 'assets/astrabiz-logo.png';
import chatIcon from 'assets/Chat.svg';
import xIcon from 'assets/Close Square.svg';
import usaIcon from 'assets/Group 1171275979.svg';
import ngnIcon from 'assets/nga.svg';
import downloadIcon from 'assets/Paper Download.svg';
import { jsPDF } from 'jspdf';
import { FC, useState } from 'react';
import 'jspdf-autotable';

import InitiateRequest from './InitiateRequestModal';

interface DataType {
  description: string;
  key: React.Key;
  date: string;
  transaction_id: string;
  type: string;
  status: string;
  amount: string;
  reference: string;
  fees: string;
}

interface TransactionModalProps {
  openModal: boolean;
  close: () => void;
  transaction: DataType | null;
}

interface InfoRowProps {
  label: string;
  value: string;
  imgSrc?: string;
  statusStyles?: CustomCSSProperties;
}
interface CustomCSSProperties extends React.CSSProperties {
  dotColor?: string;
}

const TransactionModal: FC<TransactionModalProps> = ({ openModal, close, transaction }) => {
  const [initiateRequestOpen, setInitiateRequestOpen] = useState(false);

  const handleDownloadReceipt = () => {
    if (transaction) {
      const doc = new jsPDF();

      if (!logo) {
        throw new Error('Logo not found');
      }

      const imgData = logo;
      doc.addImage(imgData, 'PNG', 13, 10, 30, 3);

      doc.setFontSize(18);
      doc.text('Transaction Receipt', 14, 20);

      const tableColumn = [
        { header: 'Field', dataKey: 'field' },
        { header: 'Details', dataKey: 'details' },
      ];

      const tableRows = [
        { field: 'Transaction ID', details: transaction.transaction_id },
        { field: 'Amount', details: transaction.amount },
        { field: 'Transaction Type', details: transaction.type },
        { field: 'Transaction Status', details: transaction.status },
        { field: 'Payment Reference', details: transaction.reference },
        { field: 'Fees', details: transaction.fees },
        { field: 'Date of Transaction', details: transaction.date },
        { field: 'Transaction Description', details: transaction.description },
      ];

      doc.autoTable({
        columns: tableColumn,
        body: tableRows,
        startY: 30,
        margin: { left: 14, right: 14 },
        styles: { cellPadding: 3, fontSize: 12 },
        headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 14 },
        bodyStyles: { fillColor: [240, 240, 240] },
      });

      doc.save(`Transaction-${transaction.transaction_id}.pdf`);
    }
    close();
  };

  const getStatusStyle = (status: string): CustomCSSProperties => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '55%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '60%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '50%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '40%',
        };
      default:
        return {};
    }
  };

  const InfoRow: FC<InfoRowProps> = ({ label, value, imgSrc, statusStyles }) => (
    <div className="flex flex-col gap-2 w-[50%]">
      <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">{label}</p>
      <div
        className="flex items-center gap-2"
        style={
          statusStyles && {
            padding: '0px 0 0 10px',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            height: '22px',
            fontSize: '12px',
            backgroundColor: statusStyles.backgroundColor,
            color: statusStyles.color,
            width: statusStyles.width,
          }
        }
      >
        {statusStyles?.dotColor && (
          <div
            style={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: statusStyles.dotColor,
            }}
          ></div>
        )}
        {imgSrc && <img src={imgSrc} alt={label} className="h-[15.94px] w-[15.94px]" />}
        <h1
          className="text-[14px] leading-[20px] font-[700]"
          style={statusStyles && { color: statusStyles.color }}
        >
          {value}
        </h1>
      </div>
    </div>
  );

  const getCurrencyFromAmount = (amount: string): 'NGN' | 'USD' => {
    if (amount.includes('NGN') || amount.includes('₦')) {
      return 'NGN';
    } else if (amount.includes('USD') || amount.includes('$')) {
      return 'USD';
    } else {
      return 'NGN';
    }
  };

  const getIconForCurrency = (currency: 'NGN' | 'USD') => {
    if (currency === 'NGN') {
      return ngnIcon;
    } else if (currency === 'USD') {
      return usaIcon;
    }
    return undefined;
  };

  const determineCurrency = transaction ? getCurrencyFromAmount(transaction.amount) : 'USD';

  const handleSupportClick = () => {
    close();
    setInitiateRequestOpen(true);
  };

  return (
    <>
      <Modal
        open={openModal}
        onCancel={close}
        className="tarns_modal"
        width="531px"
        style={{
          top: 0,
          height: '100vh',
          padding: 0,
        }}
        styles={{ body: { overflowY: 'auto', height: 'calc(100vh - 55px)' } }}
      >
        <div className="flex flex-col gap-3 px-[34px] py-[20px] relative">
          <div className="flex justify-between text-[#343434] font-[900] text-[24px] leading-[40px]">
            <h1>Transaction Details</h1>
            <button onClick={close}>
              <img src={xIcon} alt="Close" className="h-[18px] w-[18px]" />
            </button>
          </div>

          {transaction && (
            <div className="border-[0.5px] border-[#EAECF0] w-[447px] h-[400px] rounded-[10px] mt-[30px] flex flex-col gap-12 p-[30px]">
              <div className="flex justify-between">
                <InfoRow label="Transaction Type" value={transaction.type} />
                <InfoRow
                  label="Transaction Status"
                  value={transaction.status}
                  statusStyles={getStatusStyle(transaction.status)}
                />
              </div>
              <div className="flex justify-between">
                <InfoRow label="Transaction ID" value={transaction.transaction_id} />
                <InfoRow label="Payment Reference" value={transaction.reference} />
              </div>
              <div className="flex justify-between">
                <InfoRow
                  label="Amount"
                  value={transaction.amount}
                  imgSrc={getIconForCurrency(determineCurrency)}
                />
                <InfoRow
                  label="Fees"
                  imgSrc={getIconForCurrency(determineCurrency)}
                  value={transaction.fees}
                />
              </div>
              <div className="flex justify-between">
                <InfoRow label="Date of Transaction" value={transaction.date} />
                <InfoRow label="Transaction Description" value={transaction.description} />
              </div>
            </div>
          )}

          <div className="flex justify-between items-center mt-[60px]">
            <button className="flex items-center gap-2" onClick={handleDownloadReceipt}>
              <img src={downloadIcon} alt="Download" />
              <p>Download Receipt</p>
            </button>
            <button
              className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] w-[170px] font-[700] text-[14px] text-[#ffff] leading-[18.86px]"
              onClick={handleSupportClick}
            >
              <img src={chatIcon} alt="Chat" />
              Talk to Support
            </button>
          </div>

          <div className="mt-[60px]">
            <button
              onClick={close}
              className="flex justify-center gap-2 items-center text-[#1174ED] text-[16px] leading-[22.4px] font-[700]"
            >
              <img src={leftIcon} alt="Back" />
              Back
            </button>
          </div>
        </div>
      </Modal>

      <InitiateRequest
        openModal={initiateRequestOpen}
        close={() => setInitiateRequestOpen(false)}
      />
    </>
  );
};

export default TransactionModal;
