import { notification, Switch } from 'antd';
import userIcon from 'assets/Add.svg';
import leftArrowIcon from 'assets/Arrow - Left b.svg';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import deleteIcon from 'assets/delete.png';
import KycSideBar from 'components/KybSideBar';
import { Key, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_ROUTE, DOCUMENT_ROUTE } from 'routes/path';
import { useGetUserMutation } from 'services/auth';
import { useGetBusinessMutation } from 'services/business';
import { useDeleteKYBMutation, useGetKYBMutation, useSubmitKYBMutation } from 'services/kyb';
import { useAppDispatch, useAppSelector } from 'store';
import { setUser } from 'store/auth';
import { setBusiness } from 'store/business';
import { setKYB } from 'store/kyb';
import { User } from 'types/auth';

import MyModal from './AddShareHolder';

const Information = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { kyb } = useAppSelector((state) => state.kyb);
  const { user } = useAppSelector((state) => state.auth);
  const [submitKYB, { isLoading }] = useSubmitKYBMutation();
  const [deleteKYB] = useDeleteKYBMutation();
  const navigate = useNavigate();

  const [getUser] = useGetUserMutation();
  const [getBusiness] = useGetBusinessMutation();
  const [getKYB] = useGetKYBMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUser({})
      .unwrap()
      .then((response: { data: User }) => {
        dispatch(setUser(response.data));
      });
  }, [dispatch, getUser]);

  useEffect(() => {
    getBusiness({})
      .unwrap()
      .then((response) => {
        dispatch(setBusiness(response.data));
        getUserKyb();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBusiness, getKYB]);

  const getUserKyb = () => {
    getKYB({})
      .unwrap()
      .then((response) => {
        dispatch(setKYB(response.data));
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getUser({});
      getBusiness({});
      getUserKyb();
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser, getBusiness, getKYB]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  interface ErrorResponse {
    data: {
      message: string;
    };
  }

  const handleSubmit = async () => {
    try {
      await submitKYB({}).unwrap();
      navigate(BASE_ROUTE);
      notification.success({
        message: 'Success',
        description: 'KYB Submitted Successfully',
      });
    } catch (error) {
      const err = error as ErrorResponse;
      if (
        err &&
        typeof err === 'object' &&
        'data' in err &&
        typeof err.data === 'object' &&
        'message' in err.data
      ) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      } else {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      }
    }
  };

  const handleDeleteShareholder = async (shareholderId: string) => {
    try {
      await deleteKYB({ shareholderId }).unwrap();
      notification.success({
        message: 'Success',
        description: 'Shareholder Deleted Successfully',
      });
      getUserKyb();
    } catch (error) {
      const err = error as ErrorResponse;
      if (
        err &&
        typeof err === 'object' &&
        'data' in err &&
        typeof err.data === 'object' &&
        'message' in err.data
      ) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      } else {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      }
    }
  };

  return (
    <KycSideBar>
      <div className="bg-[#fff] rounded-[10px] sha py-[60px] px-[20px] lg:px-[40px] lg:w-[1030px] flex flex-col justify-between">
        <div className="flex flex-col gap-5 xl:w-[882px]">
          <h1 className="font-[900] text-[28px] leading-[30.61px] text-[#343434]">
            Shareholder’s Information
          </h1>
          <p className="lg:w-[680px] text-[#000000] font-[500] text-[16px] leading-[21.6px]">
            We are required to collect, verify, and maintain information on your corporate
            shareholders and business owners - also known as Ultimate Beneficial Owners (UBO).
            <br /> <br />
            Provide the following information about the individuals or businesses that directly or
            indirectly own at least 25% or more of your business.
          </p>
          <p className="font-[400] text-[16px] leading-[21.6px] text-[#000000]">
            Add additional shareholders if you have more than one shareholders.
          </p>
          <div className="flex gap-4">
            <button
              onClick={showModal}
              className="py-[7px] px-[20px] border border-[#1174ED] rounded-[10px] font-[700] text-[14px] leading-[18.86px] text-[#1174ED] flex gap-2"
            >
              <img src={userIcon} alt="user icon" />
              Add Individual Owner
            </button>
            <button
              onClick={showModal}
              className="py-[7px] px-[20px] border border-[#1174ED] rounded-[10px] font-[700] text-[14px] leading-[18.86px] text-[#1174ED] flex gap-2"
            >
              <img src={userIcon} alt="user icon" />
              Add Corporate Owner
            </button>
          </div>
          <MyModal isOpen={isModalOpen} handleCancel={handleCancel} getUserKyb={getUserKyb} />
          <div className="flex flex-col gap-2 pt-[25px]">
            <h1 className="font-[900] text-[28px] leading-[30.61px] text-[#343434]">
              Existing Shareholders
            </h1>
            <p className="font-[400] text-[16px] leading-[21.6px] text-[#000000]">
              Selected shareholders for onboarding verification
            </p>
          </div>
          {user && (
            <div className="h-[126px] xl:w-[882px] border-dashed border-[1.21px] border-[#D0D0D0] bg-[#FFFFFF] flex justify-between items-center p-[15px] font-[500] rounded-[12.92px]">
              <div className="flex gap-2">
                <div className="bg-[#EA63071A] w-[68px] h-[68px] rounded-[40px] font-[500] text-[28px] leading-[40.6px] text-[#EA6307] flex justify-center items-center">
                  <h1>{`${user.last_name?.charAt(0) || ''}`}</h1>
                  <h1>{`${user.first_name?.charAt(0) || ''}`}</h1>
                </div>
                <div className="font-[700] text-[18px] leading-[26.1px] flex flex-col gap-2">
                  <h1>{`${user.name || ''}`}</h1>
                  <div className="flex gap-2">
                    <p className="text-[#1174ED] py-[6px] px-[13px] bg-[#1174ED14] rounded-[10px] text-[14px] leading-[20.3px]">
                      Individual
                    </p>
                    <p className="text-[#1174ED] py-[6px] px-[13px] bg-[#1174ED14] rounded-[10px] text-[14px] leading-[20.3px]">
                      Director
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex justify-end">
                  <p className="text-[#037847] py-[6px] px-[13px] bg-[#0378471A] rounded-[10px] text-[14px] leading-[20.3px]">
                    Manually added
                  </p>
                </div>
                <div className="flex items-center gap-2 text-[#000000] font-[500] leading-[18.9px]">
                  <p>Select for Onboarding</p>
                  <Switch defaultChecked />
                </div>
              </div>
            </div>
          )}
          {kyb?.shareholders?.length ? (
            kyb.shareholders.map(
              (
                shareholder: {
                  id: string;
                  first_name: string;
                  last_name: string;
                },
                index: Key | null | undefined
              ) => (
                <div
                  key={index}
                  className="h-[126px] xl:w-[882px] border-dashed border-[1.21px] border-[#D0D0D0] bg-[#FFFFFF] flex justify-between items-center p-[15px] font-[500] rounded-[12.92px]"
                >
                  <div className="flex gap-2">
                    <div className="bg-[#EA63071A] w-[68px] h-[68px] rounded-[40px] font-[500] text-[28px] leading-[40.6px] text-[#EA6307] flex justify-center items-center">
                      <h1>{`${shareholder.first_name.charAt(0)}${shareholder.last_name.charAt(
                        0
                      )}`}</h1>
                    </div>
                    <div className="font-[700] text-[18px] leading-[26.1px] flex flex-col gap-2">
                      <h1>{`${shareholder.first_name} ${shareholder.last_name}`}</h1>
                      <div className="flex gap-2">
                        <p className="text-[#1174ED] py-[6px] px-[13px] bg-[#1174ED14] rounded-[10px] text-[14px] leading-[20.3px]">
                          Individual
                        </p>
                        <p className="text-[#1174ED] py-[6px] px-[13px] bg-[#1174ED14] rounded-[10px] text-[14px] leading-[20.3px]">
                          Director
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-end">
                      <button onClick={() => handleDeleteShareholder(shareholder.id)}>
                        <img src={deleteIcon} alt={deleteIcon} className="h-[20px]" />
                      </button>
                    </div>
                    <div className="flex justify-end">
                      <p className="text-[#037847] py-[6px] px-[13px] bg-[#0378471A] rounded-[10px] text-[14px] leading-[20.3px]">
                        Shareholder added
                      </p>
                    </div>
                    <div className="flex items-center gap-2 text-[#000000] font-[500] leading-[18.9px]">
                      <p>Select for Onboarding</p>
                      <Switch defaultChecked />
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <p className="text-[#000000] font-[500] leading-[18.9px]">No shareholders available</p>
          )}
        </div>
        <div className="flex justify-between items-center xl:w-[882px] mt-[50px]">
          <Link to={BASE_ROUTE}>
            <button className="flex items-center gap-1 text-[#1174ED] font-[700] leading-[22.4px] text-[16px]">
              <img src={leftArrowIcon} alt="left arrow icon" />
              <p>Save and continue later</p>
            </button>
          </Link>
          <div className="flex items-center gap-2">
            <Link to={DOCUMENT_ROUTE}>
              <button className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] border border-[#1174ED] bg-inherit text-[#1174ED] rounded-[10px]">
                <img src={leftArrowIcon} alt="left arrow icon" />
                Previous
              </button>
            </Link>
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px]"
            >
              {isLoading ? 'Submitting...' : 'Submit KYB'}
              <img src={rightArrowIcon} alt="right arrow icon" />
            </button>
          </div>
        </div>
      </div>
    </KycSideBar>
  );
};

export default Information;
