import AuthBackgroundLines from 'assets/auth-background-lines.svg';
import CurrencyMap from 'assets/currency-map.svg';
import PaymentsMockup from 'assets/payments-mockup.svg';

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <div className="w-full grid grid-cols-2">
      <div className="h-screen overflow-y-scroll">{children}</div>
      <div className="bg-[#1174ED0D] h-screen relative">
        <img src={AuthBackgroundLines} alt="auth background lines" className="h-screen w-full" />
        {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"> */}
        {/* <img
          src={PaymentsMockup}
          className="absolute top-0 left-0 right-0 h-screen w-full object-cover "
          alt="payments-mockup"
        /> */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="relative">
            <img src={CurrencyMap} alt="currency map" className="absolute -top-10 -left-10" />
            <div className="bg-white p-8 shadow-lg rounded-xl w-max">
              <p className="text-2xl font-bold">Efficiently Manage</p>
              <p className="text-2xl font-black text-astravest-blue">International Transactions</p>
            </div>
            <img src={PaymentsMockup} alt="payments mockup" />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default AuthLayout;
