/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table, TableColumnsType } from 'antd';
import arrowIcon from 'assets/Arrow - Right 2.svg';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import debitIcon from 'assets/Group 7.svg';
import receiveIcon from 'assets/Group 107.svg';
import usdIcon from 'assets/Group 1171275979.svg';
import sgdIcon from 'assets/Group 1171275982.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import cnyIcon from 'assets/Mask group.svg';
import searchIcon from 'assets/search.svg';
import AgentSideBar from 'components/agentComponents/AgentSideBar';
import CurrencyButton from 'components/agentComponents/CurrencyButton';
import { useCallback, useEffect, useState } from 'react';
import { useGetAgentTransactionMutation } from 'services/agentServices/agent';
import { useAppDispatch } from 'store';
import { setTransaction } from 'store/Transaction';
import { Transactions } from 'types/agent';
import { DataType, WalletData } from 'types/Transaction';

const AgentWallet = (_props: any) => {
  const [getAgentTransaction] = useGetAgentTransactionMutation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [isRotated, setIsRotated] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<DataType[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [walletData, setWalletData] = useState<WalletData>({
    available: 0,
    balance: 0,
    currency: '',
  });

  const updateWalletData = useCallback(
    (currency: string) => {
      if (_props.accounts && _props.accounts[currency]) {
        const { available, balance } = _props.accounts[currency];
        setWalletData({ available: Number(available), balance: Number(balance), currency });
      } else {
        setWalletData({ available: '', balance: '', currency: `${currency} not found` });
      }
    },
    [_props.accounts]
  );

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency);
    updateWalletData(currency);
  };

  const currencies = [
    { name: 'NGN', icon: ngnIcon },
    { name: 'USD', icon: usdIcon },
    { name: 'CNY', icon: cnyIcon },
    { name: 'SDG', icon: sgdIcon },
    { name: 'HKD', icon: hkdIcon },
  ];

  const getCurrencyIcon = (currency: string) => {
    switch (currency) {
      case 'NGN':
        return ngnIcon;
      case 'USD':
        return usdIcon;
      case 'CNY':
        return cnyIcon;
      case 'SDG':
        return sgdIcon;
      case 'HKD':
        return hkdIcon;
      default:
        return '';
    }
  };

  const availableCurrencies = currencies.filter((currency) => {
    const isAvailable = _props.accounts && _props.accounts[currency.name];
    const matchesSearch = currency.name.toLowerCase().includes(searchTerm.toLowerCase());

    return isAvailable && matchesSearch;
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRefresh = () => {
    setLoading(true);
    setIsRotated(!isRotated);
    getAgentTransaction({})
      .unwrap()
      .then((response) => {
        const transactions = response.data.map((transaction: Transactions) => {
          const formatAmount = (value: string) => {
            return new Intl.NumberFormat('en-US').format(parseFloat(value));
          };
          return {
            key: transaction.id,
            date: new Date(transaction.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }),
            rate: transaction.payment_info.transaction.rate,
            amount: `${transaction.amount.currency} ${formatAmount(transaction.amount.value)}`,
            payment_type: transaction.payment_info.transaction.type,
            purpose: transaction.payment_info.transaction.purpose,
            fees: `${formatAmount(transaction.fees.value)}`,
            source_amount: transaction.fees.value,
            total_amount: `${formatAmount(transaction.amount.value)}`,
            status: transaction.status,
            description: transaction.payment_info.transaction.description,
            type: transaction.type,
          };
        });
        dispatch(setTransaction(transactions));
        setData(transactions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '65%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '60%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '55%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '68%',
        };
      default:
        return {};
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      responsive: ['lg'],
      render: (text: string, record: DataType) => {
        const icon =
          record.status === 'Initiated' || record.status === 'Failed' ? debitIcon : receiveIcon;
        return (
          <div className="flex items-center gap-2">
            <img src={icon} alt={icon} className="w-[18.9px] h-[18.9px]" />
            {text}
          </div>
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Payment Type',
      dataIndex: 'payment_type',
      responsive: ['md'],
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
    },
    {
      title: 'Transaction Status',
      dataIndex: 'status',
      responsive: ['md'],
      render: (text: string) => {
        const { backgroundColor, color, dotColor, width } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '0px 0  0 10px',
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              width: width,
              height: '22px',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
                marginRight: '8px',
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
  ];

  const refreshWalletData = () => {
    setLoadingWallet(true);
    setTimeout(() => {
      updateWalletData(selectedCurrency);
      setLoadingWallet(false);
    }, 1000);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.type.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase())
  );

  const [showAll, setShowAll] = useState(false);
  const rowsToShow = showAll ? data.length : 10;

  const toggleView = () => {
    setShowAll(!showAll);
  };

  return (
    <AgentSideBar>
      <div className="flex flex-col gap-5 md:gap-10">
        <div className="input flex items-center">
          <img src={searchIcon} alt={searchIcon} className="relative left-[30px] -ml-[30px]" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for account"
            className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] md:w-[400px] py-[16.21px]"
          />
        </div>
        <div className="flex flex-col gap-2 md:gap-5">
          <div className="flex justify-start">
            <h1 className="font-[900] text-[18px] md:text-[24px] leading-[40px] text-[#343434]">
              Wallets
            </h1>
          </div>
          <div
            className="flex gap-5 overflow-x-scroll scrollbar-hide"
            style={{
              WebkitOverflowScrolling: 'touch',
            }}
          >
            {availableCurrencies.map((currency) => (
              <CurrencyButton
                key={currency.name}
                currency={currency}
                isActive={selectedCurrency === currency.name}
                onClick={() => handleCurrencyChange(currency.name)}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-[20px]">
          <div className="flex flex-col gap-5 md:w-[50%] lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px]">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-3 font-[900] text-[12px] md:text-[16px] leading-[18px] text-[#343434]">
                <img
                  src={getCurrencyIcon(selectedCurrency)}
                  alt={selectedCurrency}
                  className="md:w-[42px] md:h-[42px] w-[30px] h-[30px]"
                />
                <p>{selectedCurrency}</p>
                <p>Account</p>
              </div>
              <button
                onClick={refreshWalletData}
                className="flex items-center font-[500] text-[10px] md:text-[14px] leading-[20px]"
              >
                <img src={refreshIcon} alt={refreshIcon} />
                <p>Refresh</p>
              </button>
            </div>

            <div className="flex justify-between gap-4">
              <div className="flex items-center bg-[#F5F5F5] w-[50%] h-[70px] md:h-[103px] rounded-[10px]">
                {loadingWallet ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <Spin size="small" />
                  </div>
                ) : (
                  <div className="flex flex-col items-center w-[100%]">
                    <p className="text-[#666666] font-[500] text-[10px] md:text-[12px] leading-[16.2px]">
                      Available Balance
                    </p>
                    <h1 className="text-[#343434] font-[900] whitespace-nowrap text-[12px] md:text-[14px] leading-[40px]">
                      {walletData.available.toLocaleString()} {walletData.currency}
                    </h1>
                  </div>
                )}
              </div>
              <div className="flex items-center bg-[#F5F5F5] w-[50%] h-[70px] md:h-[103px] rounded-[10px]">
                {loadingWallet ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <Spin size="small" />
                  </div>
                ) : (
                  <div className="flex flex-col items-center w-[100%]">
                    <p className="text-[#666666] font-[500] text-[10px] md:text-[12px] leading-[16.2px]">
                      Total Balance
                    </p>
                    <h1 className="text-[#343434] font-[900] whitespace-nowrap text-[12px] md:text-[14px] leading-[40px]">
                      {walletData.balance.toLocaleString()} {walletData.currency}
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="md:w-[50%] lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[10px] md:p-[15px] flex flex-col gap-2">
            <h1 className="font-[900] text-[18px] md:text-[24px] leading-[40px] text-[#343434]">
              Quick Actions
            </h1>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <button
                  className="h-[78px] w-[160px] rounded-[10px]
                   bg-[#F5F5F5] flex flex-col p-[6px]"
                >
                  <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                    Send Money
                  </h1>
                  <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                    Pay out via our global network.
                  </p>
                </button>

                <button
                  className="h-[78px] w-[160px] rounded-[10px]
                   bg-[#F5F5F5] flex flex-col p-[6px]"
                >
                  <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                    Convert & Pay
                  </h1>
                  <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                    Change to other currency and make payments.
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-[20px]">
          <div className="flex flex-col gap-5 md:w-[50%] lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px]">
            <div className="flex justify-between md:items-center">
              <h1 className="font-[900] text-[18px] md:text-[24px] text-[#343434] leading-[40px]">
                Bank Details
              </h1>
              <div className="flex items-center gap-2 text-[#8894EC] font-[700] text-[12.57px] leading-[18.86px]">
                <button className="btn bg-[#8894EC33] border border-[#8894EC] rounded-[30px] w-[100% ] md:w-[79px] h-[33px] flex justify-center items-center py-[20px] px-[7px]">
                  Local
                </button>
                <button className="btn border border-[#8894EC] rounded-[30px] w-[100%] md:w-[79px] h-[33px] flex justify-center items-center py-[20px] px-[7px]">
                  Global
                </button>
              </div>
            </div>
            <div className="lg:w-[70%] flex justify-between gap-[30px] md:gap-0">
              <div className="flex flex-col gap-3 w-[40%] whitespace-nowrap md:whitespace-normal">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Name</p>
                <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
                  Astravest for Business
                </h1>
              </div>
              <div className="flex flex-col gap-3 w-[60%]">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                  Account Number
                </p>
                <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
                  23451674578
                </h1>
              </div>
            </div>
            <div className="lg:w-[97%] flex justify-between gap-[60px] md:gap-0 mt-[20px]">
              <div className="flex flex-col gap-3 w-[40%]">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Bank Name</p>
                <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
                  United Bank for Africa
                </h1>
              </div>
              <div className="flex flex-col gap-3 w-[60%]">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Bank Address</p>
                <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
                  14, Norway, Amsterdam, Greenwich
                </h1>
              </div>
            </div>
          </div>
          <div className="box flex flex-col gap-3 md:w-[50%] lg:h-[269px] border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px]">
            <h1 className="font-[900] text-[16px] leading-[18px] text-[#343434]">
              Important details
            </h1>
            <div className="flex gap-2">
              <div>
                <div className="h-[21px] w-[21px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff]">
                  i
                </div>
              </div>
              <div className="text-[#666666] font-[500] text-[13px] leading-[19px] flex flex-col gap-1">
                <p>
                  A service request is a way of quickly communicating your needs or concerns to our
                  team. Whether you're seeking assistance, reporting an issue, or requesting a
                  service, we're here to help.
                </p>
                <p>
                  Your service request has been successfully initiated and is currently under
                  review.
                </p>
                <p>
                  Our team is dedicated to addressing your request promptly and efficiently. You can
                  expect a follow-up email shortly with further details regarding the progress of
                  your request, including any additional information required, estimated timelines,
                  and next steps.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
          <div className="flex flex-col gap-2 md:flex-row justify-between border-b border-b-[#EAECF0] p-[15px]">
            <h1 className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px]">
              Recent Transactions
            </h1>
            <div className="flex gap-3">
              <div className="input flex items-center">
                <img
                  src={searchIcon}
                  alt={searchIcon}
                  className="relative left-[30px] -ml-[20px] md:-ml-[30px]"
                />
                <input
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="Search for account"
                  className="bg-[#F5F5F5] pl-[35px] lg:pl-[35px] rounded-[10px] h-[40px] py-[16.21px] w-[150px] md:w-[100%] lg:w-[300px]"
                />
              </div>
              <button
                onClick={handleRefresh}
                className="flex items-center font-[500] text-[10px] md:text-[14px] md:leading-[20px]"
              >
                <img
                  src={refreshIcon}
                  alt={refreshIcon}
                  className={`transition-transform duration-500 ease-in-out ${
                    isRotated ? 'rotate-360' : ''
                  }`}
                />
                <p>Refresh</p>
              </button>
              <button
                onClick={toggleView}
                className="flex items-center gap-2 text-[#1174ED] font-[700] text-[10px] md:text-[14px] md:leading-[18.9px] whitespace-nowrap"
              >
                {showAll ? 'View Less' : 'View All'}
                <img src={arrowIcon} alt={arrowIcon} className="hidden md:block" />
              </button>
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={filteredData.slice(0, rowsToShow)}
              size="middle"
              className="table"
              id="table"
              pagination={false}
            />
          )}
        </div>
      </div>
    </AgentSideBar>
  );
};

export default AgentWallet;
