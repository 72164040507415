import arrowIcon from 'assets/Arrow - Right 2.svg';
import { bool, func, shape, string } from 'prop-types';

interface Currency {
  name: string;
  icon: string;
}

interface Props {
  currency: Currency;
  isActive: boolean;
  onClick: () => void;
}

const CurrencyButton: React.FC<Props> = ({ currency, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`flex px-[20px] border-[1.5px] border-[#1174ED] bg-[#F5F5F5] h-[83px] w-[264px] rounded-[10px] ${
      isActive ? '' : 'opacity-[0.4] border-[0] border-[#F5F5F5]'
    }`}
  >
    <div className="flex justify-between items-center w-[100%]">
      <div className="flex items-center gap-2">
        <img src={currency.icon} alt={currency.icon} className="w-[42px] h-[42px]" />
        <p className="font-[900] text-[16px] leading-[18px] text-[#343434]">{currency.name}</p>
      </div>
      {isActive && <img src={arrowIcon} alt={arrowIcon} className="h-[18px] w-[18px]" />}
    </div>
  </button>
);

CurrencyButton.propTypes = {
  currency: shape({
    name: string.isRequired,
    icon: string.isRequired,
  }).isRequired,
  isActive: bool.isRequired,
  onClick: func.isRequired,
};

export default CurrencyButton;
