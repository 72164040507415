interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <div className="bg-[#FAFCFF] h-screen">
      <div className="flex min-h-screen mx-auto">{children}</div>
    </div>
  );
};

export default Layout;
