import { Modal } from 'antd';
import chatIcon from 'assets/Chat.svg';
import xIcon from 'assets/Close Square.svg';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

interface InitiateRequestProps {
  openModal: boolean;
  close: () => void;
}

const validationSchema = Yup.object({
  reason: Yup.string()
    .required('Reason is required')
    .matches(/^[a-zA-Z\s']*$/, 'Only alphabets are allowed'),
  message: Yup.string()
    .required('Message is required')
    .matches(/^[a-zA-Z\s]*$/, 'Only alphabets are allowed'),
});

const InitiateRequest: FC<InitiateRequestProps> = ({ openModal, close }) => {
  const formik = useFormik({
    initialValues: {
      reason: '',
      message: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const mailtoLink = `mailto:support@astrabiz.com?subject=${encodeURIComponent(
        'Service Request'
      )}&body=${encodeURIComponent(
        `Reason: ${values.reason}\nMessage: ${values.message}\n\nBest Regards,\nAstraBiz Team.`
      )}`;
      window.location.href = mailtoLink;
      resetForm();
      close();
    },
  });

  const handleClose = () => {
    formik.resetForm();
    close();
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleClose}
      className="modal"
      width="55%"
      style={{
        top: 0,
        height: '100vh',
        padding: 0,
      }}
      styles={{ body: { overflowY: 'auto', height: 'calc(100vh - 55px)' } }}
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 px-[34px] py-[20px]">
        <div className="flex justify-between text-[#343434] font-[900] text-[24px] leading-[40px]">
          <h1>Initiate a Request</h1>
          <button type="button" onClick={handleClose}>
            <img src={xIcon} alt="Close" className="h-[18px] w-[18px]" />
          </button>
        </div>
        <div className="flex flex-col gap-[30px]">
          <div className="flex flex-col gap-2">
            <p className="text-[#344054] leading-[20px] font-[500] text-[14px]">
              Please input a reason for initiating this request
            </p>
            <input
              type="text"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="border border-[#EAECF0] h-[51px] rounded-[10px] w-[498px] p-2"
            />
            {formik.touched.reason && formik.errors.reason ? (
              <div className="text-red-500 text-sm">{formik.errors.reason}</div>
            ) : null}
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#344054] leading-[20px] font-[500] text-[14px]">Your Message</p>
            <textarea
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-[498px] h-[215px] rounded-[10px] border border-[#EAECF0] p-[10px]"
            ></textarea>
            {formik.touched.message && formik.errors.message ? (
              <div className="text-red-500 text-sm">{formik.errors.message}</div>
            ) : null}
          </div>

          {formik.values.reason && formik.values.message && (
            <div className="box flex flex-col gap-3 h-[266px] w-[498px] border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px]">
              <div className="flex gap-2">
                <div>
                  <div className="h-[21px] w-[21px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff]">
                    i
                  </div>
                </div>
                <div className="flex flex-col gap-3 text-[#666666] font-[500] text-[14px] leading-[19px] w-[426px]">
                  <p>
                    A service request is a way of quickly communicating your needs or concerns to
                    our team. Whether you're seeking assistance, reporting an issue, or requesting a
                    service, we're here to help.
                  </p>
                  <p>
                    Your service request has been successfully initiated and is currently under
                    review.
                  </p>
                  <p>
                    Our team is dedicated to addressing your request promptly and efficiently. You
                    can expect a follow-up email shortly with further details regarding the progress
                    of your request, including any additional information required, estimated
                    timelines, and next steps.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex gap-[50px] items-center mt-[60px]">
            <button
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
              className={`flex justify-center text-[#fff] gap-2 items-center rounded-[10px] px-[7px] py-[20px] h-[40px] w-[223px] font-[700] text-[14px] leading-[18.86px] ${
                formik.isValid && formik.dirty ? 'bg-[#1174ED]' : 'bg-[#1174ed42]'
              }`}
            >
              <img src={chatIcon} alt="Chat" />
              Initiate Service Request
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="flex justify-center gap-2 items-center text-[#344054] text-[14px] leading-[20px] font-[500]"
            >
              Cancel Request
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default InitiateRequest;
