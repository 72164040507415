import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left b.svg';
import logoIcon from 'assets/astrabiz-logo.png';
import indicatorIcon1 from 'assets/indicator2 (1).svg';
import indicatorIcon2 from 'assets/indicator2 (2).svg';
import indicatorIcon3 from 'assets/indicator2 (3).svg';
import indicatorIcon4 from 'assets/indicator2 (4).svg';
import indicatorIcon from 'assets/indicator2.svg';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASE_ROUTE, DETAIL_ROUTE } from 'routes/path';

const items: MenuProps['items'] = [
  {
    label: 'Private Limited Company',
    key: '1',
  },
  {
    label: 'Public Limited Company',
    key: '2',
  },
  {
    label: 'Limited Liability Company',
    key: '3',
  },
  {
    label: 'Sole Trader/ Sole Proprietor',
    key: '4',
  },
];

const menuProps = {
  items,
  // onClick: handleMenuClick,
};

const Required = () => {
  useEffect(() => {
    document.title = 'AstraBiz | Required Document';
  }, []);

  return (
    <div className="bg-[url('/src/assets/bg.svg')] bg-[#fff] bg-no-repeat bg-cover bg-center pb-[50px] pt-[30px] md:px-[35px] lg:px-[70px] flex flex-col gap-[20px]">
      <div className="flex justify-center">
        <img src={logoIcon} alt={logoIcon} />
      </div>
      <div className="font-[700] text-[12.24px] leading-[17.14px] text-[#1174ED] flex items-center gap-1">
        <img src={leftArrowIcon} alt={leftArrowIcon} />
        <Link to={BASE_ROUTE}>
          <button>Back to Dashboard</button>
        </Link>
      </div>
      <div className="flex justify-center">
        <div className="flex gap-8">
          <div className="flex flex-col w-[40%] gap-[30px]">
            <div className="bg-[#FCFCFC] h-[650px] rounded-[10px] sha lg:px-[30px] px-[15px] pt-[40px] flex flex-col gap-[40px]">
              <h1 className="font-[900] xl:text-[21.43px] lg:text-[18px] leading-[30.61px] text-[#343434]">
                Verify your business in these steps
              </h1>
              <div className="flex flex-col gap-[30px]">
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon} alt={indicatorIcon} />
                  <p>Business Information</p>
                </div>
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon1} alt={indicatorIcon1} />
                  <p>Business Questionnaire</p>
                </div>
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon2} alt={indicatorIcon2} />
                  <p>Business Documents</p>
                </div>
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon3} alt={indicatorIcon3} />
                  <p>Shareholder’s Information</p>
                </div>
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon4} alt={indicatorIcon4} />
                  <p>Shareholder’s Verification</p>
                </div>
              </div>
            </div>
            <Link
              to={DETAIL_ROUTE}
              className="bg-[#1174ED] rounded-[12px] px-[15.91px] py-[5.57px] h-[50px] flex justify-center text-[#FFFFFF] font-[700] lg:text-[18px] xl:text-[20px] leading-[15px] shaw items-center cursor-pointer"
            >
              Proceed to Activate your account
            </Link>
          </div>
          <div className="bg-[#FCFCFC] w-[60%] h-[785px pb-[50px] rounded-[10px] sha lg:px-[30px] px-[15px] pt-[40px] flex flex-col gap-[40px]">
            <div className="flex gap-[20px]">
              <h1 className="font-[900] text-[#343434] lg:text-[18px] xl:text-[21.43px] leading-[30.61px] xl:w-[450px]">
                View documents required
              </h1>
              <Dropdown menu={menuProps}>
                <Button>
                  <Space className="xl:gap-[60px]">
                    Select Company Type
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon} alt={indicatorIcon} />
                  <p>Business Information</p>
                </div>
                <div className="relative left-9 text-[#666666] font-[400] text-[14px] leading-[18.9px] flex flex-col gap-2">
                  <p>a. Certificate of Incorporation - Operating Agency.</p>
                  <p>
                    b. Shareholding structure <span className="text-[red]">*</span>
                  </p>
                  <div className="flex flex-col gap-2">
                    <p>
                      c. Proof of Business Address <span className="text-[red]">*</span>
                    </p>
                    <ol className="list-decimal relative left-[34px] flex flex-col gap-2">
                      <li>
                        Business bank statement <span className="text-[red]">*</span>
                      </li>
                      <li>
                        Business utility bill <span className="text-[red]">*</span>
                      </li>
                      <li>
                        Valid lease agreement <span className="text-[red]">*</span>
                      </li>
                    </ol>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p>
                      d. Supporting Documents <span className="text-[red]">*</span>
                    </p>
                    <ol className="list-decimal relative left-[34px] flex flex-col gap-2">
                      <li>
                        Source of funds <span className="text-[red]">*</span>
                      </li>
                      <li>
                        AML documents <span className="text-[red]">*</span>
                      </li>
                      <li>
                        Supplier Invoices <span className="text-[red]">*</span>
                      </li>
                      <li>Other</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 font-[700] lg:text-[18px] leading-[24.3px] text-[#000000]">
                  <img src={indicatorIcon1} alt={indicatorIcon1} />
                  <p>Shareholder Documents</p>
                </div>
                <div className="relative left-9 text-[#666666] font-[400] text-[14px] leading-[18.9px] flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <p>
                      a. Identification Documents <span className="text-[red]">*</span>
                    </p>
                    <ol className="list-decimal relative left-[34px] flex flex-col gap-2">
                      <li>
                        National card or Driver’s License (back and front){' '}
                        <span className="text-[red]">*</span>
                      </li>
                      <li>
                        Passport <span className="text-[red]">*</span>
                      </li>
                      <li>Resident permit ID/ U.S green card</li>
                    </ol>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p>
                      b. Proof of Residential Address <span className="text-[red]">*</span>
                    </p>
                    <ol className="list-decimal relative left-[34px] flex flex-col gap-2">
                      <li>
                        Personal bank statement <span className="text-[red]">*</span>
                      </li>
                      <li>
                        Utility bill <span className="text-[red]">*</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Required;
