/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table, TableColumnsType } from 'antd';
import arrowIcon from 'assets/Arrow - Right 2.svg';
import debitIcon from 'assets/Group 7.svg';
import receiveIcon from 'assets/Group 107.svg';
import usaIcon from 'assets/Group 1171275979.svg';
import sdgIcon from 'assets/Group 1171275982.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import cynIcon from 'assets/Mask group.svg';
import ngnIcon from 'assets/nga.svg';
import searchIcon from 'assets/search.svg';
import AddFund from 'components/userComponent/AddFundModal';
import Convertion from 'components/userComponent/ConvertionModal';
import CurrencyButton from 'components/userComponent/CurrencyButton';
import SendMoney from 'components/userComponent/SendMoneyModal';
import SideBar from 'components/userComponent/SideBar';
import { useCallback, useEffect, useState } from 'react';
import { useGetTransactionMutation } from 'services/Transaction';
import { useAppDispatch } from 'store';
import { setTransaction } from 'store/Transaction';
import { DataType, WalletData } from 'types/Transaction';

const Account = (_props: any) => {
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const [walletData, setWalletData] = useState<WalletData>({
    available: 0,
    balance: 0,
    currency: '',
  });

  type ModalType = 'addFunds' | 'sendMoney' | 'convertPay';

  const [modalState, setModalState] = useState<{ [key in ModalType]?: boolean }>({
    addFunds: false,
    sendMoney: false,
    convertPay: false,
  });
  const [getTransaction] = useGetTransactionMutation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const handleRefresh = () => {
    setIsRotated(!isRotated);
    setLoading(true);
    getTransaction({})
      .unwrap()
      .then((response) => {
        const transactions = response.data.map(
          (transaction: {
            id: string;
            created_at: string | number | Date;
            reference: string;
            type: string;
            status: string;
            amount: {
              value: string;
              currency: string;
            };
            description: string;
            fees: { value: string };
          }) => {
            const formatTo12Digit = (value: string) => {
              return value.slice(0, 12).padEnd(12, '0');
            };

            const formatAmount = (value: string) => {
              return new Intl.NumberFormat('en-US').format(parseFloat(value));
            };

            return {
              key: transaction.id,
              date: new Date(transaction.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
              transaction_id: formatTo12Digit(transaction.reference),
              type: transaction.type,
              status: transaction.status,
              amount: `${transaction.amount.currency} ${formatAmount(transaction.amount.value)}`,
              reference: formatTo12Digit(transaction.reference),
              description: transaction.description,
              fees: transaction.fees.value,
            };
          }
        );
        dispatch(setTransaction(transactions));
        setData(transactions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '63%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '60%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '50%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '50%',
        };
      default:
        return {};
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: string, record: DataType) => {
        const icon =
          record.status === 'Initiated' || record.status === 'Failed' ? debitIcon : receiveIcon;
        return (
          <div className="flex items-center gap-2">
            <img src={icon} alt={icon} className="w-[18.9px] h-[18.9px]" />
            {text}
          </div>
        );
      },
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        const { backgroundColor, color, dotColor, width } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '2px 8px 2px 6px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: width,
              height: '22px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
                marginRight: '8px',
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.type.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase())
  );

  const [showAll, setShowAll] = useState(false);
  const rowsToShow = showAll ? data.length : 10;

  const toggleView = () => {
    setShowAll(!showAll);
  };

  const toggleModal = (type: ModalType, isOpen: boolean) => {
    setModalState((prevState) => ({ ...prevState, [type]: isOpen }));
  };

  const showModal = (type: ModalType) => toggleModal(type, true);
  const handleCancel = (type: ModalType) => toggleModal(type, false);

  const [activeButton, setActiveButton] = useState<'addFunds' | 'sendMoney' | 'convertPay'>(
    'addFunds'
  );

  const handleButtonClick = (buttonName: 'addFunds' | 'sendMoney' | 'convertPay') => {
    setActiveButton(buttonName);
  };

  const updateWalletData = useCallback(
    (currency: string) => {
      if (_props.accounts && _props.accounts[currency]) {
        const { available, balance } = _props.accounts[currency];
        setWalletData({ available: Number(available), balance: Number(balance), currency });
      } else {
        setWalletData({ available: '', balance: '', currency: `${currency} not found` });
      }
    },
    [_props.accounts]
  );

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency);
    updateWalletData(currency);
  };

  const refreshWalletData = () => {
    setIsRotated(!isRotated);
    setLoadingWallet(true);
    setTimeout(() => {
      updateWalletData(selectedCurrency);
      setLoadingWallet(false);
    }, 1000);
  };

  useEffect(() => {
    updateWalletData(selectedCurrency);
  }, [_props.accounts, selectedCurrency, updateWalletData]);

  const currencies = [
    { name: 'NGN', icon: ngnIcon },
    { name: 'USD', icon: usaIcon },
    { name: 'CNY', icon: cynIcon },
    { name: 'SDG', icon: sdgIcon },
    { name: 'HKD', icon: hkdIcon },
  ];

  const getCurrencyIcon = (currency: string) => {
    switch (currency) {
      case 'NGN':
        return ngnIcon;
      case 'USD':
        return usaIcon;
      case 'CNY':
        return cynIcon;
      case 'SDG':
        return sdgIcon;
      case 'HKD':
        return hkdIcon;
      default:
        return '';
    }
  };

  const availableCurrencies = currencies.filter((currency) => {
    const isAvailable = _props.accounts && _props.accounts[currency.name];
    const matchesSearch = currency.name.toLowerCase().includes(searchTerm.toLowerCase());

    return isAvailable && matchesSearch;
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <SideBar isKycVerified={true}>
      <div className="flex flex-col gap-10">
        <div className="input flex items-center">
          <img src={searchIcon} alt={searchIcon} className="relative left-[30px] -ml-[30px]" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for account"
            className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] w-[400px] py-[16.21px]"
          />
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex justify-start">
            <h1 className="font-[900] text-[24px] leading-[40px] text-[#343434]">Accounts</h1>
          </div>
          <div className="flex gap-5">
            {availableCurrencies.map((currency) => (
              <CurrencyButton
                key={currency.name}
                currency={currency}
                isActive={selectedCurrency === currency.name}
                onClick={() => handleCurrencyChange(currency.name)}
              />
            ))}
          </div>
        </div>

        <div className="flex justify-between gap-[20px]">
          <div className="flex flex-col gap-5 w-[50%] h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px]">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-3 font-[900] text-[16px] leading-[18px] text-[#343434]">
                <img
                  src={getCurrencyIcon(selectedCurrency)}
                  alt={selectedCurrency}
                  className="w-[42px] h-[42px]"
                />
                <p>{selectedCurrency}</p>
                <p>Account</p>
              </div>
              <button
                className="flex items-center font-[500] text-[14px] leading-[20px]"
                onClick={refreshWalletData}
              >
                <img
                  src={refreshIcon}
                  alt={refreshIcon}
                  className={`transition-transform duration-500 ease-in-out ${
                    isRotated ? 'rotate-360' : ''
                  }`}
                />
                Refresh
              </button>
            </div>

            <div className="flex justify-between gap-4">
              <div className="flex items-center bg-[#F5F5F5] w-[50%] h-[103px] rounded-[10px]">
                {loadingWallet ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <Spin size="small" />
                  </div>
                ) : (
                  <div className="flex flex-col items-center w-[100%]">
                    <p className="text-[#666666] font-[500] text-[12px] leading-[16.2px]">
                      Available Balance
                    </p>
                    <h1 className="text-[#343434] font-[900] text-[14px] leading-[40px]">
                      {walletData.available.toLocaleString()} {walletData.currency}
                    </h1>
                  </div>
                )}
              </div>
              <div className="flex items-center bg-[#F5F5F5] w-[50%] h-[103px] rounded-[10px]">
                {loadingWallet ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <Spin size="small" />
                  </div>
                ) : (
                  <div className="flex flex-col items-center w-[100%]">
                    <p className="text-[#666666] font-[500] text-[12px] leading-[16.2px]">
                      Total Balance
                    </p>
                    <h1 className="text-[#343434] font-[900] text-[14px] leading-[40px]">
                      {walletData.balance.toLocaleString()} {walletData.currency}
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-[50%] h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px] flex flex-col gap-2">
            <h1 className="font-[900] text-[24px] leading-[40px] text-[#343434]">Quick Actions</h1>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <button
                  onClick={() => {
                    handleButtonClick('addFunds');
                    showModal('addFunds');
                  }}
                  className={`h-[78px] w-[145px] rounded-[10px] ${
                    activeButton === 'addFunds' ? 'border border-[#1174ED]' : ''
                  } bg-[#F5F5F5] flex flex-col p-[6px]`}
                >
                  <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                    Add Funds
                  </h1>
                  <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                    Fund your account with your local currency.
                  </p>
                </button>
                <AddFund openModal={!!modalState.addFunds} close={() => handleCancel('addFunds')} />

                <button
                  onClick={() => {
                    handleButtonClick('sendMoney');
                    showModal('sendMoney');
                  }}
                  className={`h-[78px] w-[145px] rounded-[10px] ${
                    activeButton === 'sendMoney' ? 'border border-[#1174ED]' : ''
                  } bg-[#F5F5F5] flex flex-col p-[6px]`}
                >
                  <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                    Send Money
                  </h1>
                  <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                    Pay out via our global network.
                  </p>
                </button>
                <SendMoney
                  openMoneyModal={!!modalState.sendMoney}
                  closeModaL={() => handleCancel('sendMoney')}
                />

                <button
                  onClick={() => {
                    handleButtonClick('convertPay');
                    showModal('convertPay');
                  }}
                  className={`h-[78px] w-[145px] rounded-[10px] ${
                    activeButton === 'convertPay' ? 'border border-[#1174ED]' : ''
                  } bg-[#F5F5F5] flex flex-col p-[6px]`}
                >
                  <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                    Convert & Pay
                  </h1>
                  <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                    Change to other currency and make payments.
                  </p>
                </button>
                <Convertion
                  isOpen={!!modalState.convertPay}
                  handleCancel={() => handleCancel('convertPay')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[20px]">
          <div className="flex flex-col gap-5 w-[50%] h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px]">
            <div className="flex justify-between items-center">
              <h1 className="font-[900] text-[24px] text-[#343434] leading-[40px]">Bank Details</h1>
              <div className="flex items-center gap-2 text-[#8894EC] font-[700] text-[12.57px] leading-[18.86px]">
                <button className="btn bg-[#8894EC33] border border-[#8894EC] rounded-[30px] w-[79px] h-[33px] flex justify-center items-center py-[20px] px-[7px]">
                  Local
                </button>
                <button className="btn border border-[#8894EC] rounded-[30px] w-[79px] h-[33px] flex justify-center items-center py-[20px] px-[7px]">
                  Global
                </button>
              </div>
            </div>
            <div className="w-[70%] flex justify-between">
              <div className="flex flex-col gap-3">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Name</p>
                <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                  Astravest for Business
                </h1>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                  Account Number
                </p>
                <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                  23451674578
                </h1>
              </div>
            </div>
            <div className="w-[97%] flex justify-between mt-[20px]">
              <div className="flex flex-col gap-3">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Bank Name</p>
                <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                  United Bank for Africa
                </h1>
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Bank Address</p>
                <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                  14, Norway, Amsterdam, Greenwich
                </h1>
              </div>
            </div>
          </div>
          <div className="box flex flex-col gap-3 w-[50%] h-[269px] border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px]">
            <h1 className="font-[900] text-[16px] leading-[18px] text-[#343434]">
              Important details
            </h1>
            <div className="flex gap-2">
              <div>
                <div className="h-[21px] w-[21px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff]">
                  i
                </div>
              </div>
              <div className="text-[#666666] font-[500] text-[13px] leading-[19px] flex flex-col gap-1">
                <p>
                  A service request is a way of quickly communicating your needs or concerns to our
                  team. Whether you're seeking assistance, reporting an issue, or requesting a
                  service, we're here to help.
                </p>
                <p>
                  Your service request has been successfully initiated and is currently under
                  review.
                </p>
                <p>
                  Our team is dedicated to addressing your request promptly and efficiently. You can
                  expect a follow-up email shortly with further details regarding the progress of
                  your request, including any additional information required, estimated timelines,
                  and next steps.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
          <div className="flex justify-between border-b border-b-[#EAECF0] p-[15px]">
            <h1 className="font-[900] text-[#343434] text-[24px] leading-[40px]">
              Account Transactions
            </h1>
            <div className="input flex items-center">
              <img src={searchIcon} alt={searchIcon} className="relative left-[30px] -ml-[30px]" />
              <input
                type="text"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search by status"
                className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] py-[16.21px] w-[300px]"
              />
            </div>
            <button
              onClick={handleRefresh}
              className="flex items-center font-[500] text-[14px] leading-[20px]"
            >
              <img
                src={refreshIcon}
                alt={refreshIcon}
                className={`transition-transform duration-500 ease-in-out ${
                  isRotated ? 'rotate-360' : ''
                }`}
              />
              <p>Refresh</p>
            </button>
            <button
              onClick={toggleView}
              className="flex items-center gap-2 text-[#1174ED] font-[700] text-[14px] leading-[18.9px]"
            >
              {showAll ? 'View Less' : 'View All'}
              <img src={arrowIcon} alt={arrowIcon} />
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="flex flex-col">
              <Table
                columns={columns}
                dataSource={filteredData.slice(0, rowsToShow)}
                pagination={false}
                size="middle"
                className="table"
                id="table"
              />
            </div>
          )}
        </div>
      </div>
    </SideBar>
  );
};

export default Account;
