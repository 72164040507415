import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, notification } from 'antd';
import leftArrowIcon from 'assets/Arrow1 - Left.svg';
import logoIcon from 'assets/astrabiz-logo.png';
import activeIcon from 'assets/Frame 6.svg';
import HomeIcon from 'assets/HomeIcon';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_ROUTE } from 'routes/path';
import { useGetUserMutation } from 'services/auth';
import { useVerifyMutation } from 'services/kyc';
import { KycInput } from 'types/kyc';
import * as yup from 'yup';
interface ErrorResponse {
  data: {
    message: string;
  };
}

const validationSchema = yup.object().shape({
  bvn: yup
    .string()
    .required('BVN is required')
    .matches(/^\d{11}$/, 'BVN must be 11 digits'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  middle_name: yup.string().optional(),
});

const VerifyKyc = () => {
  const [verify] = useVerifyMutation();
  const [getUser] = useGetUserMutation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bvn: '',
      dateOfBirth: undefined,
      middle_name: '',
    },
  });

  const [firstName, setFirstName] = useState('');
  const [lasttName, setLastName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const response = await getUser({});
        if (response?.data) {
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    };
    fetchAgent();
  }, [getUser, setValue]);

  const onSubmit = async () => {
    const { bvn, dateOfBirth } = getValues();

    try {
      await validationSchema.validate({ bvn, dateOfBirth });

      const userResponse = await getUser({});
      const user = userResponse?.data?.data;

      const payload: KycInput = {
        id: bvn,
        is_subject_consent: true,
        metadata: {
          user_id: user?.id || 'default-user-id',
          env: import.meta.env.VITE_ENV || 'dev',
          type: 'bvn',
        },
        validations: {
          data: {
            first_name: firstName,
            last_name: lasttName,
            date_of_birth: format(new Date(dateOfBirth), 'yyyy-MM-dd'),
          },
        },
      };

      await verify(payload).unwrap();
      notification.success({
        message: 'Success',
        description: 'KYC verification submitted successfully',
      });
      navigate(BASE_ROUTE);
    } catch (error) {
      const err = error as ErrorResponse;
      if (err?.data?.message) {
        notification.error({
          message: 'Error',
          description: err.data.message,
        });
      } else if (error instanceof yup.ValidationError) {
        notification.error({
          message: 'Validation Error',
          description: error.message,
        });
      }
    }
  };

  useEffect(() => {
    document.title = 'AstraBiz | KYC Verification';
  }, []);

  return (
    <div className="flex">
      <div className="w-[20%] h-[100vh] flex flex-col gap-[30px] pt-[30px] items-center bg-[#F5F9FF] sha sticky top-0">
        <div className="px-[30px] xl:px-0">
          <img src={logoIcon} alt={logoIcon} />
        </div>
        <div className="flex flex-col gap-8 items-center lg:items-start text-[#09090A] text-[16px] not-italic font-[400] leading-[22.4px] pt-[20px]">
          <Link to={BASE_ROUTE}>
            <div className="flex items-center gap-2 cursor-pointer">
              <HomeIcon />
              <h1 className="text-[#09090A] font-[400] text-[16px] leading-[22.4px]">Dashboard</h1>
            </div>
          </Link>
          <div className="flex gap-2 text-[#333333] font-[500] text-[16px] leading-[21.6px]">
            <img src={activeIcon} alt={activeIcon} />
            <p className="pt-[5px]">KYC Verification</p>
          </div>
        </div>
      </div>

      <div className="bg-[url('/src/assets/bg.svg')] bg-[#fff] bg-no-repeat bg-cover w-[80%] flex pt-[60px] pb-[40px] px-[40px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-[#fff] rounded-[10px] sha p-[40px] lg:w-[1030px] flex flex-col"
        >
          <h1 className="pt-[25px font-[900] text-[28px] leading-[30.61px] text-[#343434]">
            Activate your account
          </h1>
          <div>
            <div className="flex flex-col gap-5 lg:w-[641px]">
              <p className="font-[400] text-[16px] leading-[21.6px] mt-3 w-[450px] text-[#000000]">
                We are required to collect and verify your personal information. Provide the
                following information for your KYC verification.
              </p>
              <div className="flex flex-col gap-[25px]">
                <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2">
                  <p className="text-[#000000]">
                    Your BVN (Bank Verification Number)<span className="text-[red]">*</span>
                  </p>
                  <Controller
                    name="bvn"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        placeholder="Enter BVN"
                        className="border border-[#D0D0D0] w-[642px] h-[54px] rounded-[12px] px-[20px]"
                      />
                    )}
                  />
                  {errors.bvn && <p className="text-red-500 text-sm mt-1">{errors.bvn.message}</p>}
                </div>

                <div className="font-[500] text-[16px] leading-[22.4px] flex flex-col gap-2">
                  <p className="text-[#000000]">
                    Legal First Name (as it appears on your documents)
                    <span className="text-[red]">*</span>
                  </p>
                  <input
                    value={firstName}
                    readOnly
                    placeholder="Enter First Name"
                    className="border border-[#D0D0D0] w-[642px] h-[54px] rounded-[12px] px-[20px]"
                  />
                </div>

                <div className="flex gap-2 justify-between">
                  <div className="font-[500] text-[16px] leading-[22.4px] w-[50%] flex flex-col gap-2">
                    <p className="text-[#000000]">
                      Legal Last Name<span className="text-[red]">*</span>
                    </p>
                    <input
                      value={lasttName}
                      readOnly
                      placeholder="Enter Last Name"
                      className="border border-[#D0D0D0] h-[54px] rounded-[12px] px-[20px]"
                    />
                  </div>
                  <div className="font-[500] text-[16px] leading-[22.4px] w-[50%] flex flex-col gap-2">
                    <p className="text-[#000000]">
                      Middle Name<span className="text-[red]">*</span>
                    </p>
                    <Controller
                      name="middle_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="border border-[#D0D0D0] h-[54px] rounded-[12px] px-[20px]"
                          placeholder="Enter your middle name"
                        />
                      )}
                    />
                    {errors.middle_name && (
                      <p className="text-red-500 text-sm mt-1">{errors.middle_name.message}</p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-[500] text-[16px] leading-[22.4px] text-[#000000]">
                    Date of Birth<span className="text-[red]">*</span>
                  </p>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        className="lg:w-[641px] md:w-[28.5rem] font-[500] text-[16px] leading-[22.4px]"
                        placeholder="Select date of birth"
                      />
                    )}
                  />
                  {errors.dateOfBirth && (
                    <p className="text-red-500 text-sm mt-1">{errors.dateOfBirth.message}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className="flex justify-center mt-5 items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px]"
                >
                  Continue
                  <img src={leftArrowIcon} alt={leftArrowIcon} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyKyc;
