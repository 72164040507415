import { Modal, notification, Radio } from 'antd';
import leftIcon from 'assets/Arrow - Left b.svg';
import chatIcon from 'assets/Chat.svg';
import xIcon from 'assets/Close Square.svg';
import copyIcon from 'assets/copy-svgrepo-com.svg';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useWalletFundMutation } from 'services/wallet';
import { useAppSelector } from 'store';
import * as Yup from 'yup';

import InitiateRequest from './InitiateRequestModal';

interface AddFundProps {
  openModal: boolean;
  close: () => void;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

const validationSchema = Yup.object({
  amount: Yup.string().matches(/^\d+$/, 'Amount must be a number').required('Amount is required'),
});

const AddFund: FC<AddFundProps> = ({ openModal, close }) => {
  const [initiateRequestOpen, setInitiateRequestOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<'bank' | 'FlutterWave'>('bank');
  const [walletFund, { isLoading }] = useWalletFundMutation();
  const { user } = useAppSelector((state) => state.auth);

  const handleSupportClick = () => {
    close();
    setInitiateRequestOpen(true);
  };

  const formik = useFormik({
    initialValues: { amount: '' },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          amount: Number(values.amount),
          redirect_url: `${import.meta.env.VITE_REDIRECT_URL}`,
        };

        const response = await walletFund(payload).unwrap();

        if (response.data && response.data.link) {
          window.location.href = response.data.link;
        } else {
          notification.error({
            message: 'Error',
            description: 'Payment initialization failed. Invalid response from server.',
          });
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    },
  });

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={close}
        className="modal"
        width="55%"
        style={{
          top: 0,
          height: '100vh',
          padding: 0,
        }}
        styles={{ body: { overflowY: 'auto', height: 'calc(100vh - 55px)' } }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-3 px-[34px] py-[20px]">
            <div className="flex justify-between text-[#343434] font-[900] text-[24px] leading-[40px]">
              <h1>Add funds to your NGN Account</h1>
              <button onClick={close}>
                <img src={xIcon} alt={xIcon} className="h-[18px] w-[18px]" />
              </button>
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                  Select Method
                </p>
                <Radio.Group
                  onChange={(e) => setSelectedMethod(e.target.value)}
                  value={selectedMethod}
                  className="flex w-[553px] font-[700] text-[14px] leading-[20px]"
                >
                  <Radio.Button
                    value="bank"
                    className="w-[50%] h-[40px] items-center flex justify-center"
                  >
                    Bank Transfer
                  </Radio.Button>
                  <Radio.Button
                    value="FlutterWave"
                    className="w-[50%] h-[40px] items-center flex justify-center"
                  >
                    FlutterWave
                  </Radio.Button>
                </Radio.Group>
              </div>

              {selectedMethod === 'bank' && (
                <div className="flex flex-col gap-6 border-[0.5px] border-[#EAECF0] w-[553px] h-[269px] rounded-[10px] p-[20px]">
                  <div className="flex justify-between">
                    <h1 className="text-[#343434] font-[900] text-[20px] leading-[40px]">
                      Bank Details
                    </h1>
                    <button className="flex justify-center items-center text-[#8894EC] font-[700] text-[12.57px] leading-[18.86px] px-[20px] py-[7px] border border-[#8894EC] bg-[#8894EC33] rounded-[30px] w-[72px] h-[33px]">
                      Local
                    </button>
                  </div>
                  <div className="flex flex-col gap-6">
                    <div className="flex w-[100%]">
                      <div className="flex flex-col gap-2 w-[40%]">
                        <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                          Account Name
                        </p>
                        <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                          Astravest for Business
                        </h1>
                      </div>
                      <div className="flex flex-col gap-2 w-[60%]">
                        <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                          Account Number
                        </p>
                        <div className="flex items-center gap-2">
                          <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                            23451674578
                          </h1>
                          <img src={copyIcon} alt={copyIcon} />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-[100%]">
                      <div className="flex flex-col gap-2 w-[40%]">
                        <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                          Bank Name
                        </p>
                        <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                          United Bank for Africa
                        </h1>
                      </div>
                      <div className="flex flex-col gap-2 w-[60%]">
                        <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">
                          Bank Address
                        </p>
                        <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                          14, Norway, Amsterdam, Greenwich
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedMethod === 'FlutterWave' && (
                <div className="flex flex-col gap-4 border-[0.5px] border-[#EAECF0] w-[553px] rounded-[10px] p-[20px]">
                  <div>
                    <h1 className="text-[#343434] font-[900] text-[20px] leading-[40px]">
                      Add Funds with FlutterWave
                    </h1>
                    <p className="text-[#343434] font-[500] text-[16px] leading-[20px]">
                      You can add funds to your account through FlutterWave’s secure payment
                      gateway.
                    </p>
                  </div>
                  <div>
                    <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">Amount</p>
                    <input
                      className="h-[46px] p-3 w-[70%] border border-[#D0D0D0] rounded-[8px]"
                      placeholder="50000"
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.amount && formik.errors.amount && (
                      <div className="text-red-500 text-sm">{formik.errors.amount}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] w-[170px] font-[700] text-[14px] text-[#ffff] leading-[18.86px]"
                    disabled={isLoading || !formik.isValid}
                  >
                    {isLoading ? 'Processing...' : 'Pay Now'}
                  </button>
                </div>
              )}
            </div>

            {!user?.is_bvn_verified && (
              <div className="box flex flex-col gap-3 h-[93px] border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px]">
                <div className="flex gap-2">
                  <div>
                    <div className="h-[21px] w-[21px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff] relative top-4">
                      i
                    </div>
                  </div>
                  <div>
                    <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
                      Activate your Account
                    </h1>
                    <div className="text-[#666666] font-[500] text-[13px] leading-[19px] flex flex-col gap-1">
                      <p>
                        Your account is approved to receive payments from your company's other bank
                        accounts only. Payments from individuals or other companies will be rejected
                        and returned to the sender.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-between items-center mt-[60px]">
              <button
                onClick={close}
                className="flex justify-center gap-2 items-center text-[#1174ED] text-[16px] leading-[22.4px] font-[700]"
              >
                <img src={leftIcon} alt={leftIcon} />
                Back
              </button>
              <button
                onClick={handleSupportClick}
                className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] w-[170px] font-[700] text-[14px] text-[#ffff] leading-[18.86px]"
              >
                <img src={chatIcon} alt={chatIcon} />
                Talk to Support
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <InitiateRequest
        openModal={initiateRequestOpen}
        close={() => setInitiateRequestOpen(false)}
      />
    </div>
  );
};

export default AddFund;
