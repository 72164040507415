import { Button, Checkbox, Input, notification, Select } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left b.svg';
import rightArrowIcon from 'assets/Arrow1 - Left.svg';
import ngaIcon from 'assets/nga.svg';
import KycSideBar from 'components/KybSideBar';
import { ErrorMessage, Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BASE_ROUTE, DETAIL_ROUTE, QUESTIONAIRE_ROUTE } from 'routes/path';
import { useGetBusinessMutation } from 'services/business';
import { useGetKYBMutation, useUpdateKybMutation } from 'services/kyb';
import { useAppDispatch } from 'store';
import { businessSelector, setBusiness } from 'store/business';
import { setKYB } from 'store/kyb';
import { FormValues, KybUpdateInput } from 'types/kyb';
import * as Yup from 'yup';

const { Option } = Select;

const Address = () => {
  const { business } = useSelector(businessSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const { state: detailsData } = location;
  const [updateKyb, { isLoading }] = useUpdateKybMutation();
  const [getBusiness] = useGetBusinessMutation();
  const [getKYB] = useGetKYBMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    business.address;
    business.country;
    business.city;
    business.postal_code;
  }, [business.address, business.country, business.city, business.postal_code]);

  useEffect(() => {
    getBusiness({})
      .unwrap()
      .then((response) => {
        dispatch(setBusiness(response.data));
        getUserKyb();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBusiness, getKYB]);

  const getUserKyb = () => {
    getKYB({})
      .unwrap()
      .then((response) => {
        dispatch(setKYB(response.data));
      });
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    town: Yup.string().required('Town/City is required'),
    postalCode: Yup.string().required('ZIP/Postal code is required'),
    isEditable: Yup.boolean(),
  });

  const handleFormSubmit = async (values: FormValues, successMessage: string, route: string) => {
    const payload: KybUpdateInput = {
      legal_business_name: detailsData.legalName,
      has_another_trading_name: detailsData.isEditable,
      business_identification_number: detailsData.businessId,
      business_address: values.address,
      city: values.town,
      country_of_business_registration: values.country,
      postal_code: values.postalCode,
      has_a_different_address: values.isEditable,
    };

    try {
      await updateKyb(payload).unwrap();
      notification.success({
        message: 'Success',
        description: successMessage,
      });
      navigate(route);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error updating Business Details and Address',
      });
    }
  };

  const handleSaveAndContinueLater = async (values: FormValues) => {
    await handleFormSubmit(values, 'Business Information saved', BASE_ROUTE);
  };

  const handleSubmit = async (values: FormValues) => {
    await handleFormSubmit(values, 'Business Information Updated', QUESTIONAIRE_ROUTE);
  };

  return (
    <KycSideBar>
      <div className="bg-[#fff] rounded-[10px] sha py-[60px] px-[40px] lg:w-[1030px]">
        <Formik
          initialValues={{
            address: business.address,
            country: business.country,
            town: business.city,
            postalCode: business.postal_code,
            isEditable: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, handleChange, handleBlur }) => (
            <FormikForm className="flex flex-col justify-between h-[550px]">
              <div className="flex flex-col gap-5 lg:w-[641px]">
                <div className="flex flex-col">
                  <h1 className="text-[#343434] font-[900] leading-[30.61px] text-[28px]">
                    Business Address
                  </h1>
                  <p className="font-[400] text-[16px] leading-[21.6px] mt-3 text-[#000000]">
                    Please provide details of your business’s operating address. This should be the
                    address that appears on official documents such as bank statements.
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-[#000000] font-[500] text-[16px] leading-[22.4px]">
                    Business Address*
                  </p>
                  <Field name="address">
                    {({ field }: FieldProps<string>) => (
                      <Input
                        {...field}
                        placeholder="Registered Business Address"
                        readOnly={!values.isEditable}
                        onChange={(e) => setFieldValue('address', e.target.value)}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="address" component="div" className="text-[red]" />
                </div>
                <div className="flex lg:flex-row flex-col justify-between gap-4">
                  <div className="flex flex-col gap-2 w-[50%]">
                    <p className="text-[#000000] font-[500] text-[16px] leading-[22.4px]">
                      Country of Business Registration*
                    </p>
                    <div className="select">
                      <img
                        src={ngaIcon}
                        alt={ngaIcon}
                        className="absolute z-10 pt-[10px] pl-[10px]"
                      />
                      <Select
                        placeholder="Select Country"
                        // name="country"
                        value={values.country}
                        onChange={(value) => setFieldValue('country', value)}
                        onBlur={handleBlur}
                        className="w-[271px]"
                      >
                        <Option value={values.country}>{values.country}</Option>
                      </Select>
                    </div>
                    <ErrorMessage name="country" component="div" className="red" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-[#000000] font-[500] text-[16px] leading-[22.4px]">
                      Town/City*
                    </p>
                    <Input
                      name="town"
                      placeholder="Type your town"
                      value={values.town}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-[163px]"
                    />
                    <ErrorMessage name="town" component="div" className="text-[red]" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-[#000000] font-[500] text-[16px] leading-[22.4px]">
                      ZIP/Postal code*
                    </p>
                    <Input
                      name="postalCode"
                      placeholder="000000"
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-[163px]"
                    />
                    <ErrorMessage name="postalCode" component="div" className="text-[red]" />
                  </div>
                </div>
                <div className="text-[#000000] font-[500] text-[14px] leading-[20px] flex items-center gap-2 boxcheck">
                  <Checkbox onChange={(e) => setFieldValue('isEditable', e.target.checked)} />
                  <p>
                    Select if your registered business address is different from your physical
                    operating address
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center lg:w-[641px] mt-[50px]">
                <button
                  className="flex items-center gap-1 text-[#1174ED] font-[700] leading-[22.4px] text-[16px]"
                  type="button"
                  onClick={() => handleSaveAndContinueLater(values)}
                >
                  <img src={leftArrowIcon} alt={leftArrowIcon} />
                  <p>Save and continue later</p>
                </button>
                <div className="flex items-center gap-2">
                  <Link to={DETAIL_ROUTE}>
                    <button className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] border border-[#1174ED] bg-inherit text-[#1174ED] rounded-[10px]">
                      <img src={leftArrowIcon} alt={leftArrowIcon} />
                      Previous
                    </button>
                  </Link>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    className="flex justify-center items-center gap-2 font-[700] text-[14px] leading-[18.86px] py-[7px] px-[20px] h-[40px] bg-[#1174ED] text-[#fff] rounded-[10px]"
                  >
                    Next
                    <img src={rightArrowIcon} alt={rightArrowIcon} />
                  </Button>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </KycSideBar>
  );
};

export default Address;
