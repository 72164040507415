import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TOKEN_COOKIE } from 'constants/const';
import cookie from 'services/cookie';
import { CurrencyConversionInput, walletFundInput, walletPayOutInput } from 'types/wallet';
import { baseQuery } from 'utils/baseQuery';

const customBaseQuery = fetchBaseQuery({
  baseUrl: baseQuery.baseUrl,
  prepareHeaders: (headers) => {
    return baseQuery.prepareHeaders(headers);
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const result = await customBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearCookiesAndLogout();
  }

  return result;
};

const clearCookiesAndLogout = () => {
  document.cookie.split('; ').forEach((cookie) => {
    document.cookie = `${cookie.split('=')[0]}=;expires=${new Date(0).toUTCString()};path=/`;
  });

  window.location.href = '/login';
};

export const walletApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'walletApi',
  endpoints: (builder) => ({
    getAllWallet: builder.mutation({
      query: () => ({
        url: '/wallet',
        method: 'GET',
      }),
    }),
    getRate: builder.mutation({
      query: () => ({
        url: '/rate',
        method: 'GET',
      }),
    }),
    getPurpose: builder.mutation({
      query: () => ({
        url: '/purpose',
        method: 'GET',
      }),
    }),
    getConverRate: builder.mutation({
      query: ({ from, to, amount, type }) => ({
        url: `/rate/convert?from=${from}&to=${to}&amount=${amount}&type=${type}`,
        method: 'GET',
      }),
    }),
    walletSwap: builder.mutation({
      query: (payload: CurrencyConversionInput) => ({
        url: '/wallet/swap',
        method: 'POST',
        body: payload,
      }),
    }),
    walletPayOut: builder.mutation({
      query: (payload: walletPayOutInput) => ({
        url: '/wallet/payout',
        method: 'POST',
        body: payload,
      }),
    }),
    walletFund: builder.mutation({
      query: (payload: walletFundInput) => ({
        url: '/wallet/fund',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetAllWalletMutation,
  useGetRateMutation,
  useGetConverRateMutation,
  useWalletSwapMutation,
  useWalletPayOutMutation,
  useGetPurposeMutation,
  useWalletFundMutation,
} = walletApi;

export function getAuthToken(): { access_token: string } | null {
  return cookie.get(TOKEN_COOKIE);
}
